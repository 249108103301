import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { ITaskWithClientCase } from "../models/Task";
import { AddTaskDialog } from "../components/AddTaskDialog";
import { useEffect, useState } from "react";
import { useLazySearchTasksQuery } from "../api/task-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/lib/auth";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";
import { Badge } from "@/components/ui/badge";

const taskListColumns: MRT_ColumnDef<ITaskWithClientCase>[] = [
  {
    accessorKey: "taskReference",
    header: "Reference Number",
  },
  {
    accessorKey: "client.clientName",
    header: "Customer Name",
  },
  {
    accessorKey: "taskName",
    header: "Task Name",
  },
  {
    accessorKey: "startDate",
    header: "StartDate",
    Cell: ({ row }) => {
      const date = new Date(row.original.startDate).toLocaleDateString();
      return <span className="p-3">{date}</span>;
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    Cell: ({ row }) => {
      if (!row.original.endDate) {
        return "N/A";
      }
      return new Date(row.original.endDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "currentStatus",
    header: "Status",
  },
  {
    accessorKey: "completionRate",
    header: "Progress",
    Cell: ({ row }) => {
      return (
        <>
          <CircularPercentageIndicator value={row.original.completionRate} />
        </>
      );
    },
  },
  {
    header: "User",
    Cell: ({ row }) => {
      return (
        row.original.users?.map((user) => (
          <Badge key={user.id} className="mx-0.5">
            {user.fullName}
          </Badge>
        )) ?? "N/A"
      );
    },
  },
];

type Props = {
  status:
    | "ongoing"
    | "Started"
    | "Finished"
    | "Submitted"
    | "Canceled"
    | "Suspended";
};

export const TaskListByStatus = ({ status }: Props) => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState("");

  const [searchTask, { data: tasks, isLoading }] = useLazySearchTasksQuery();

  useEffect(() => {
    searchTask({
      data: {
        orderDirection: "desc",
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        organizationId: session?.userInfo.organizationId,
        taskStatus: status,
      },
    });
  }, [pagination.pageIndex, pagination.pageSize, status, searchTask, session]);

  return (
    <div>
      {openDialog && (
        <AddTaskDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
      <MaterialReactTable
        columns={taskListColumns}
        data={tasks?.list ?? []}
        enablePagination
        manualPagination
        rowCount={tasks?.count}
        state={{ pagination, density: "compact", globalFilter, isLoading }}
        onPaginationChange={setPagination}
        initialState={{
          pagination: { pageIndex: 1, pageSize: 10 },
        }}
        manualFiltering
        onGlobalFilterChange={setGlobalFilter}
        muiTableBodyRowProps={({ row }) => {
          let rowColor = '';
          
          const endDate = row.original.endDate ? new Date(row.original.endDate) : null;
          if (endDate) {
            const currentDate = new Date();
            const diffTime = endDate.getTime() - currentDate.getTime();
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= 0) {
              rowColor = 'rgba(220, 38, 38, 0.2)'; // lighter red for overdue
            } else if (diffDays <= 5) {
              rowColor = 'rgba(252, 165, 165, 0.2)'; // very light red for warning
            } 
            // else {
            //   rowColor = 'rgba(255, 255, 21, 0.1)'; // very light yellow for normal
            // }
          }

          return {
            onClick: () => {
              navigate(`/task/operational-task/${row.original.id}`);
            },
            sx: {
              cursor: "pointer",
              backgroundColor: rowColor,
              '&:hover': {
                backgroundColor: rowColor ? `${rowColor.replace(/[\d.]+\)$/, '0.3)')} !important` : undefined,
              },
            },
          };
        }}
      />
    </div>
  );
};
