import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { useParams } from "react-router-dom";
import {
  useGetCustomersDetailQuery,
} from "../api/customer-api";
import EditCustomer from "./EditCustomer";
import { useState } from "react";
import { ClientCard } from "./ClientCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ClientTask from "../components/CLientTask";
import ClientPayment from "../components/ClientPayment";

const CustomerDetail = () => {
  const { id } = useParams();
  const [openEditCustomer, setOpenEditCustomer] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("task");
  const { data: customerDetail } = useGetCustomersDetailQuery({
    url: `/clients/${id}`,
    method: "GET",
  });

  return (
    <>
      <DefaultPageHeader headerTitle="Customer Detail" 
      breadcrumbsLinks={[
        {
          name: "Home",
          url: "/",
        },
        {
          name: "Customer List",
          url: "/customer/list",
        }]}
      />
      {customerDetail && (
        <ClientCard
          client={customerDetail}
          showTaskCount={true}
          onEditClick={() => setOpenEditCustomer(true)}
        />
      )}

      <div className="flex flex-col bg-white rounded-lg gap-4 px-2 lg:px-8 py-4">
        <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="">
          <TabsTrigger value="task">Task</TabsTrigger>
          <TabsTrigger value="payment">Payment</TabsTrigger>
        </TabsList>
        <TabsContent value="task">
          {id && <ClientTask clientId={id} />}
        </TabsContent>
        <TabsContent value="payment">
          { id && <ClientPayment clientId={id}/>}
        </TabsContent>
      </Tabs>
      </div>
      {openEditCustomer && (
        <EditCustomer
          openForm={openEditCustomer}
          setOpenForm={setOpenEditCustomer}
          customer={customerDetail}
        />
      )}
    </>
  );
};

export default CustomerDetail;
