import { DefaultPage } from "@/components/shared/DefaultPage";
import { useState } from "react";
import { AddSectorDialog } from "./components/AddSectorDialog";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { SectorList } from "./components/SectorList";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";


export function Sectors() {
  const [openSectorDialog, setOpenSectorDialog] = useState<boolean>(false);

  return (
    <DefaultPage
      headerTitle={"Sectors"}
      headerActionButton={
            <IsPermitted requiredPermissions={[UCM_Permissions.cancreatesector]}>
            <Button onClick={() => setOpenSectorDialog(true)}>
              <Plus size={18} className="mr-2" />
              New Sector
            </Button>
            </IsPermitted>
      }
    >
      {openSectorDialog && (
        <AddSectorDialog
          openDialog={openSectorDialog}
          setOpenDialog={setOpenSectorDialog}
        />
      )}
      <SectorList/>
    </DefaultPage>
  );
}