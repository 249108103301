import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { useBackWorkflowMutation } from "../api/task-command";
import { Textarea } from "@/components/ui/textarea";
import CustomButton from "@/components/shared/Button/CustomButton";

import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";


type Props = {
  taskId: string;
  isSalesWf?: boolean;
};

export const BackTaskDialog = ({ taskId }: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [note, setNote] = useState("");

  const [backWorkflow, { isSuccess: isBackWorkflowSuccess, isLoading }] =
    useBackWorkflowMutation();

  const handleBackWorkflow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await backWorkflow({
      data: {
        taskId: taskId,
        note: note,
      },
    });
  };
  useEffect(() => {
    if (isBackWorkflowSuccess) {
      setOpenDialog(false);
    }
  }, [isBackWorkflowSuccess]);

  return (
    <div className="flex  mt-3">
      <IsPermitted requiredPermissions={[UCM_Permissions.BackToRevisionOperationalTask]}>
        <Button
          variant={"outline"}
          onClick={() => setOpenDialog(true)}
          className="border-blue-400 text-blue-500 hover:bg-blue-400 hover:text-white"
        >
          Back To Revision
        </Button>
      </IsPermitted>
      <DefaultDialog
        title="Back To Revision Workflow"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <form onSubmit={handleBackWorkflow}>
          <div className="mt-3">
            <span className="m-1">Note</span>
            <Textarea
              placeholder="Add Note"
              className="mt-2"
              required
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <div className="flex justify-center mt-3">
              <CustomButton className="w-50 items-center" isLoading={isLoading}>
                Submit
              </CustomButton>
            </div>
          </div>
        </form>
      </DefaultDialog>
    </div>
  );
};
