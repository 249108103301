import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useGetSalesWorkFlowsByCaseTypeIdQuery } from "../../api/work-flow-api";
import WorkflowBody from "./WorkflowBody";
import { Button } from "@/components/ui/button";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { Badge } from "@/components/ui/badge";
import { Copy, Pencil, Trash2 } from "lucide-react";
import {
  useDeleteSalesWorkFlowMutation,
  useDuplicateSalesWorkflowMutation,
  useUpdateSalesWorkOrderNoMutation,
} from "../../api/work-flow-command";
import { Typography } from "@mui/material";
import { AddSalesWorkFlowDialog } from "./AddSalesWorkFlowDialog";
import { ISalesWorkFlow } from "../../models/sales-wf-case";
import { CopyWorkflowDialog } from "./CopyWorkflowDialog";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";

import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../../permission";


type Props = {
  selectedCaseId: string;
};

export const SalesWorkflowList = ({ selectedCaseId }: Props) => {
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDeleteConfirmModal, setDeleteOpenConfirmModal] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<ISalesWorkFlow | null>(null);

  const { data: workflows, isLoading: isFetchingWorkFlowLoading, isFetching: isFetchingWorkFlow } =
    useGetSalesWorkFlowsByCaseTypeIdQuery({
      data: { caseTypeId: selectedCaseId },
    });

  const [initialData, setInitialData] = useState<ISalesWorkFlow[]>([]);
  const [data, setData] = useState<ISalesWorkFlow[]>([]);

  const [duplicateSalesWorkFlow, { isLoading: isLoadingDuplicatedSalesWorkFlow, isSuccess: isSuccessDuplicateSalesWorkFlow }] = useDuplicateSalesWorkflowMutation();
  const [updateWorkflowOrderNo, { isLoading: isUpdateWfOrderLoading, isSuccess: isUpdateWfSuccess }] = useUpdateSalesWorkOrderNoMutation();
  const [deleteWorkflow, { isLoading: isDeleteWfLoading, isSuccess: isDeleteWfSuccess }] = useDeleteSalesWorkFlowMutation();

  const columns = useMemo<MRT_ColumnDef<ISalesWorkFlow>[]>(() => [
    {
      accessorKey: "workFlowName",
      header: "Name",
      Cell: ({ row }) => (
        <Typography fontWeight={"bold"}>
          {row.original.workFlowName}
        </Typography>
      ),
    },
    {
      accessorKey: "notifyToClient",
      header: "Notify To Client",
      Cell: ({ row }) => (
        <Badge variant={row.original.notifyToClient ? "default" : "destructive"}>
          {row.original.notifyToClient ? "Yes" : "No"}
        </Badge>
      ),
    },
    {
      accessorKey: "hasGenericTask",
      header: "Has Generic Task",
      Cell: ({ row }) => (
        <Badge variant={row.original.hasGenericTask ? "default" : "destructive"}>
          {row.original.hasGenericTask ? "Yes" : "No"}
        </Badge>
      ),
    },
    {
      accessorKey: "isActive",
      header: "Status",
      Cell: ({ row }) => (
        <Badge variant={row.original.isActive ? "default" : "destructive"}>
          {row.original.isActive ? "Active" : "InActive"}
        </Badge>
      ),
    },
  ], []);

  const hasOrderChanged = useMemo(() => {
    if (initialData.length !== data.length) return false;
    return data.some((item, index) => item.id !== initialData[index].id);
  }, [data, initialData]);

  useEffect(() => {
    if (workflows) {
      setData(workflows);
      setInitialData(workflows);
    }
  }, [workflows]);

  const handleDeleteWorkflow = useCallback(async () => {
    if (selectedWorkflow) {
      await deleteWorkflow(selectedWorkflow.id);
    }
  }, [selectedWorkflow, deleteWorkflow]);

  const handleDuplicateWorkflow = useCallback(async (workflowId: string) => {
    await duplicateSalesWorkFlow({ data: { sourceWorkFlowId: workflowId } });
  }, [duplicateSalesWorkFlow]);

  useEffect(() => {
    if (isDeleteWfSuccess) {
      setSelectedWorkflow(null);
      setDeleteOpenConfirmModal(false);
      Notify("success", "Workflow deleted successfully!");
    }
  }, [isDeleteWfSuccess]);

  const handleSaveOrder = useCallback(() => {
    const orders = data.map((item, index) => ({ id: item.id, orderNo: index }));
    updateWorkflowOrderNo({ data: orders });
  }, [data, updateWorkflowOrderNo]);

  useEffect(() => {
    if (isUpdateWfSuccess) {
      setInitialData([...data]);
      setOpenConfirmModal(false);
    }
  }, [isUpdateWfSuccess, data]);

  useEffect(() => {
    if (isSuccessDuplicateSalesWorkFlow) {
      Notify("success", "Workflow Duplicated Successfully!");
    }
  }, [isSuccessDuplicateSalesWorkFlow]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { density: "compact" },
    state: { isLoading: isFetchingWorkFlowLoading || isFetchingWorkFlow },
    rowCount: data.length,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    renderDetailPanel: ({ row }) => <WorkflowBody workflow={row.original} />,
    enableRowOrdering: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        const draggingIndex = draggingRow?.index;
        const hoveredIndex = hoveredRow?.index;

        if (typeof draggingIndex === "number" && typeof hoveredIndex === "number") {
          setData((prevData) => {
            const newData = [...prevData];
            const [movedItem] = newData.splice(draggingIndex, 1);
            newData.splice(hoveredIndex, 0, movedItem);
            return newData;
          });
        }
      },
    }),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2 py-2">
        <IsPermitted requiredPermissions={[UCM_Permissions.CanduplicateworkflowPiplineworkflowsettings]}>
          <CustomButton
            size={"sm"}
            variant={"outline"}
            onClick={async () => {
              setSelectedWorkflow(row.original);
              await handleDuplicateWorkflow(row.original.id);
            }}
            isLoading={selectedWorkflow?.id === row.original.id && isLoadingDuplicatedSalesWorkFlow}
          >
            <Copy size={12} className="mr-2" />
            Duplicate
          </CustomButton>
        </IsPermitted>
        <IsPermitted requiredPermissions={[UCM_Permissions.CaneditworkflowsettingsPipelinesetting]}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setOpenEditDialog(true);
              setSelectedWorkflow(row.original);
            }}
          >
            <Pencil size={12} className="mr-2" />
            Edit
          </Button>
        </IsPermitted>
        <IsPermitted requiredPermissions={[UCM_Permissions.CandeleteworkflowPipelinesettings]}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setDeleteOpenConfirmModal(true);
              setSelectedWorkflow(row.original);
            }}
            className="border-transparent"
          >
            <Trash2 size={14} className="mr-2" color="red" />
          </Button>
        </IsPermitted>
      </div>
    ),
    positionActionsColumn: "last",
  });

  return (
    <>
      <div className="m-1 p-1">
        <MaterialReactTable table={table} />
        {hasOrderChanged && (
          <Button
            variant="defaultPrimary"
            size="sm"
            className="mt-3"
            onClick={() => setOpenConfirmModal(true)}
          >
            Save Workflow Order
          </Button>
        )}
        {openConfirmModal && (
          <ConfirmModal
            message="Are you Sure you want to change the order?"
            title="Order Workflow Steps"
            modalOpen
            setModalOpen={() => {}}
            type="warning"
            onCancel={() => setOpenConfirmModal(false)}
            onOk={handleSaveOrder}
            loading={isUpdateWfOrderLoading}
          />
        )}
        {openEditDialog && selectedWorkflow && (
          <AddSalesWorkFlowDialog
            workflow={selectedWorkflow}
            salesCaseTypeId={selectedCaseId}
            currentNumberOfWorkFlows={selectedWorkflow.workFlowStep}
            openDialog={openEditDialog}
            setOpenDialog={() => {
              setOpenEditDialog(false);
              setSelectedWorkflow(null);
            }}
            isEditing
          />
        )}
        {openDeleteConfirmModal && selectedWorkflow && (
          <ConfirmModal
            title="Delete Workflow"
            message="Are you Sure you want to Delete this workflow?"
            modalOpen
            setModalOpen={() => {}}
            type="error"
            onCancel={() => {
              setDeleteOpenConfirmModal(false);
              setSelectedWorkflow(null);
            }}
            onOk={handleDeleteWorkflow}
            loading={isDeleteWfLoading}
          />
        )}
      </div>
      {openCopyDialog && selectedWorkflow && (
        <CopyWorkflowDialog
          openDialog={openCopyDialog}
          setOpenDialog={setOpenCopyDialog}
          targetWorkflow={selectedWorkflow}
        />
      )}
    </>
  );
};