import { DefaultPage } from "@/components/shared/DefaultPage";
import { ICustomer } from "../models/Customer";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useGetCustomersQuery } from "../api/customer-api";
import { useState, useEffect } from "react";
import { useAuth } from "@/lib/auth";
import AddCustomer from "./AddCustomer";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import Chip from "@/components/shared/customShadCN/Chip";
import Caption from "@/components/shared/customShadCN/Caption";
import { UCM_Permissions } from "../../../../permission";
import IsPermitted from "@/lib/auth/components/IsPermitted";

export const customerListColumns: MRT_ColumnDef<ICustomer>[] = [
  {
    accessorKey: "clientType",
    header: "Customer Type",
  },
  {
    accessorKey: "clientName",
    header: "Customer Name",
  },
  // {
  //   accessorKey: "phoneNumber",
  //   header: "Phone Number",
  // },
  {
    accessorKey: "phoneNumbers",
    header: "Phone Number",
    Cell({
      row: {
        original: { phoneNumbers },
      },
    }) {
      if (phoneNumbers && phoneNumbers?.length > 1) {
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex flex-row gap-1 items-center flex-nowrap">
                  <Chip label={phoneNumbers?.[0] ?? ""} />
                  <Caption
                    className="w-8"
                    text={`+ ${phoneNumbers?.length - 1}`}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {phoneNumbers?.map((phoneNumber: string, index: number) => (
                  <div key={index}>{phoneNumber}</div>
                ))}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      } else if (phoneNumbers && phoneNumbers?.length !== 0) {
        return (
          <div className="flex gap-1 items-center">
            {phoneNumbers?.map((_, index: number) => (
              <Chip key={index} label={phoneNumbers[0]} />
            ))}
          </div>
        );
      }
    },
  },
  {
    accessorKey: "email",
    header: "Email",
    enableGlobalFilter: true,
  },
  {
    accessorKey: "country",
    header: "Country",
    enableGlobalFilter: true,
  },
  {
    accessorKey: "address",
    header: "Address",
  },
  // {
  //   id: "actions",
  //   header: "Action",
  //   enableHiding: false,
  //   Cell: ({ row }) => {
  //     return (
  //       <>
  //         <NavLink
  //           to={`/customer/${row.original.id}`}
  //           className={"underline text-blue-700"}
  //         >
  //           Detail
  //         </NavLink>
  //       </>
  //     );
  //   },
  // },
];

export function Customer() {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  // Use effect to trigger the API call when pagination changes
  const {
    data: customerList,
    isLoading,
    refetch,
  } = useGetCustomersQuery({
    url: `/clients/SearchClients`,
    method: "GET",
    data: {
      organizationId: session?.userInfo.organizationId,
      pageNumber: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
  });

  useEffect(() => {
    refetch(); // Refetch data whenever pageIndex or pageSize changes
  }, [pagination.pageIndex, pagination.pageSize, refetch]);

  return (
    <>
      <DefaultPage
        headerTitle="List of Customers"
        headerActionButton={
          <IsPermitted
            requiredPermissions={[UCM_Permissions.CancreatecustomersCustomer]}
          >
            <Button onClick={() => setOpenAddCustomer(true)}>
              Add Customer
            </Button>
          </IsPermitted>
        }
      >
        <MaterialReactTable
          columns={customerListColumns}
          data={customerList?.list ?? []}
          enablePagination
          manualPagination
          rowCount={customerList?.count ?? 0} // Total number of records (if server returns it)
          state={{ pagination, density: "compact", isLoading }}
          onPaginationChange={setPagination}
          initialState={{ pagination: { pageIndex: 1, pageSize: 50 } }} // Initial pagnation state
          muiTableBodyRowProps={(row) => ({
            onClick: () => {
              // if (
              //   session?.userInfo.permissions.every((permission) =>
              //     [UCM_Permissions.CanviewcustomerdetailsCustomer].includes(
              //       permission
              //     )
              //   )
              // ) {
              navigate(`/customer/${row.row.original.id}`);
              // }
            },
            sx: {
              cursor: "pointer",
            },
          })}
        />
      </DefaultPage>
      {openAddCustomer && (
        <AddCustomer
          openForm={openAddCustomer}
          setOpenForm={setOpenAddCustomer}
        />
      )}
    </>
  );
}
