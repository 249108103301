import { DefaultPage } from "@/components/shared/DefaultPage";

import { Button } from "@/components/ui/button";

import { useGetCaseByCaseIdQuery } from "../Case/api/case-api";
import { useState } from "react";
import { useGetWorkFlowByCaseTypeIdQuery } from "../api/work-flow-api";
import { Skeleton } from "@/components/ui/skeleton";
import { AddWorkFlowDialog } from "./components/AddWorkFlowDialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import WorkflowBody from "./components/WorkflowBody";
import { Box } from "@mui/material";
import { WorkflowDetailHeader } from "./components/WorkflowDetailHeader";
import { WorkflowList } from "./components/WorkflowList";
import { useParams } from "react-router-dom";
import { Pencil, Plus } from "lucide-react";

import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";

const Workflow = () => {
  const { id } = useParams();
  const { data: workflows, isLoading: isFetchingWorkFlowLoading } =
    useGetWorkFlowByCaseTypeIdQuery({ data: { caseTypeId: id } });
  const { data: caseDetail } = useGetCaseByCaseIdQuery(id ?? "");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  return (
    <DefaultPage headerTitle="Workflow Detail">
      <div className="h-screen">
        <div className="bg-white my-2 py-4 flex flex-col gap-4 shadow-sm rounded-lg">
          <div className="flex justify-between items-center px-2 md:px-8">
            {/* Client Information */}
            <div className="flex flex-col">
              {/* <p className="text-xl font-semibold">{client?.clientName}</p> */}
              <div className="flex gap-4">
                <div className="flex gap-2 items-center">
                  <p>Case Name</p>
                  <p className="bg-secondary px-2 rounded-full">
                    {caseDetail?.type}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <p>Sector</p>
                  <p className="bg-secondary px-2 rounded-full">
                    {caseDetail?.sector?.sectorName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 my-2">
          {isFetchingWorkFlowLoading ? (
            <Skeleton className="h-[125px] w-full rounded-xl" />
          ) : (
            id && (
              <>
                {/* Add Workflow Dialog */}
                <div className="flex justify-end">
                  <IsPermitted
                    requiredPermissions={[
                      UCM_Permissions.CancreatetasksOperationalTask,
                    ]}
                  >
                    <Button onClick={() => setOpenDialog(true)}>
                      <Plus size={18} className="mr-2" />
                      Add Workflow Step
                    </Button>
                  </IsPermitted>
                  {openDialog && (
                    <AddWorkFlowDialog
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      caseTypeId={id}
                      currentNumberOfWorkFlows={workflows?.length ?? 0}
                    />
                  )}
                </div>
                {id && (
                  <>
                    {/* <h3>
                      <span className="font-bold">Description</span>
                      <br />
                      <span className="">{selectedCase.caseDescription}</span>
                    </h3> */}
                    <span className="font-bold">Workflow Stepsss</span>
                    <WorkflowList selectedCaseId={id} />
                  </>
                )}
                {/* Workflows Section */}
                {workflows?.length === 0 ? (
                  // No workflows message
                  <div className="mt-3">
                    <p>You don't add any workflow steps for this workflow</p>
                  </div>
                ) : (
                  // Workflows list
                  <div className="mt-3 hidden">
                    <div className="mt-2 flex flex-col gap-3">
                      {workflows?.map((workflow) => (
                        <div
                          key={workflow.id}
                          className="border-[1px] rounded-sm"
                        >
                          <Accordion
                            type="single"
                            collapsible
                            className="w-full p-2"
                          >
                            <AccordionItem
                              value={workflow.id}
                              className="border-b-0"
                            >
                              <AccordionTrigger className="text-1xl accordion-trigger">
                                <Box
                                  display={"flex"}
                                  gap={1}
                                  justifyContent={"center"}
                                >
                                  {workflow.workFlowName}
                                </Box>
                              </AccordionTrigger>
                              <AccordionContent>
                                <div>
                                  <div className="flex gap-5 items-center my-2 justify-between w-90">
                                    <span className="font-bold">
                                      Workflow Details
                                    </span>
                                    <Button
                                      size={"sm"}
                                      variant={"outline"}
                                      className="mx-1"
                                      onClick={() => setOpenEditDialog(true)}
                                    >
                                      <Pencil size={14} className="mx-1" />
                                      Edit
                                    </Button>
                                  </div>
                                  <AddWorkFlowDialog
                                    workflow={workflow}
                                    caseTypeId={id}
                                    currentNumberOfWorkFlows={
                                      workflow.workFlowStep
                                    }
                                    openDialog={openEditDialog}
                                    setOpenDialog={setOpenEditDialog}
                                    isEditing
                                  />
                                  <WorkflowDetailHeader workflow={workflow} />
                                </div>

                                <WorkflowBody workflow={workflow} />
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </DefaultPage>
  );
};

export default Workflow;
