import { useGetNotificationsQuery, useMarkAsReadMutation } from "@/lib/notification/api/notification-api";
import { timeAgo } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Bell } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { NotificationModel, NotificationSource, NotificationStatus } from "@/lib/notification/model/NotificationModel";

export function DropdownNotification() {
  const navigate = useNavigate()
  const { data: notifications = [], isLoading } = useGetNotificationsQuery(undefined, {
    pollingInterval: 30000, // Refetch every 30 seconds
  });

  const [markAsRead] = useMarkAsReadMutation();

  // Calculate unread notifications count
  const unreadCount = notifications.length;

  const handleNotificationClick = (notification: NotificationModel) => {
      markAsRead({data: [notification.id]})
      notification.notificationFrom === NotificationSource.Task ?
      navigate(`/task/operational-task/${notification.taskId}`)
      : notification.notificationFrom === NotificationSource.GenericTask ?
      navigate(`/task/generic-task/${notification.taskId}`)
      : notification.notificationFrom === NotificationSource.PipeLine ?
      navigate(`/sales/pipeline-workflow/${notification.taskId}`)
      : notification.notificationFrom === NotificationSource.Tender ?
      navigate(`/sales/tender-workflow/${notification.taskId}`)
      : navigate(`/alert/alert-list/${notification.taskId}`)
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="relative outline-none hover:rounded-lg" size={"sm"}>
          <Bell className="h-[1.2rem] w-[1.2rem]" />
          <span className="absolute top-[-2px] right-[-2px] bg-red-500 text-white text-xs font-bold rounded-full px-1">
            {unreadCount > 0 ? unreadCount : "0"}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-96">
        <DropdownMenuLabel>Notifications</DropdownMenuLabel>
          <ScrollArea className="h-72">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              notifications.map((notification) => (
                <DropdownMenuItem key={notification.id} onClick={() => handleNotificationClick(notification)}>
                  <div>
                    <p className={notification.status === NotificationStatus.Unread ? "font-semibold" : ""}>{notification.message}</p>
                    <span className="text-gray-500 text-sm">{timeAgo(notification.time)}</span>
                  </div>
                </DropdownMenuItem>
              ))
            )}
          </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
