import { SetStateAction } from "react";
import { CustomList, CustomListItem } from "@/components/shared/CustomList";
import { Card, CardContent } from "@/components/ui/card";
import { Plus } from "lucide-react";
import { useCreateRoleMutation } from "../api/user-api";
import RoleDetail from "../componets/RoleDetail";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";
import { IRole } from "../models/Role";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@/components/ui/button";

interface RoleTabsProps {
  selectedRole: IRole,
  roles: IRole[],
  setSelectedRole: React.Dispatch<SetStateAction<IRole | null>>
}

const RoleTabs = ({selectedRole, roles, setSelectedRole}: RoleTabsProps): JSX.Element => {
  const [createRole] = useCreateRoleMutation();

  const handleRoleSelection = (role: IRole): void => {
    setSelectedRole(role);
  };

  const handleAddRole = async () => {
    // Call the createRole mutation
    const result = await createRole(
      {
        data: {
          role: {
            roleName: "New Role",
            description: "New Role"
          }
        }
      }
    ).unwrap();
    // Set the active role to the newly added role
    setSelectedRole(result);
  };

  return (
    <div className="flex flex-col gap-4 items-start">
      <Button variant="ghost" onClick={() => setSelectedRole(null)}><ArrowBack/></Button>
      <div className="flex">
        {/* Sidebar */}
        <div className="border-r border-gray-300 pr-4 w-60">
          {/* {isLoading && <p>Loading roles...</p>} */}
          <div className="flex justify-between bg-white rounded p-2 py-4">
            <h6 className="mb-2">Roles</h6>
          <IsPermitted requiredPermissions={[UCM_Permissions.CancreaterolesRole]}>
            <Plus onClick={handleAddRole} className="cursor-pointer" />
          </IsPermitted>
          </div>
          <CustomList>
            {roles.map((role) => (
              <CustomListItem
                key={role.id}
                onClick={() => handleRoleSelection(role)}
                className={`${
                  role.id === selectedRole.id
                    ? "bg-[#F1F5F9] text-black"
                    : "text-gray-800 bg-white"
                }`}
              >
                <div className="flex justify-between items-center">
                  <span className="font-medium">{role.roleName}</span>
                </div>
              </CustomListItem>
            ))}
          </CustomList>
        </div>

        {/* Role Detail Section */}
        <div className="flex-grow pl-4 w-full">
          <Card>
            <CardContent>
              {/* Pass active role data to RoleDetail */}
              {roles.length > 0 && selectedRole && (
                <RoleDetail
                  role={selectedRole}
                />
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RoleTabs;
