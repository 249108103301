import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import CustomButton from "@/components/shared/Button/CustomButton";

import { useEffect } from "react";
import dayjs from "dayjs";
import { useGetAlertTypeQuery } from "@/features/alert/api/alert-api";
import { useAddAlertFortTaskMutation } from "@/features/task/api/task-command";
import { AlertFrom } from "@/features/task/models/Task";
import DateInput from "@/features/task/components/form-components/DateInput";
import { z } from "zod";
import { SnoozeUnit } from "@/features/alert/models/alert";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FormSelect from "@/components/form/FormSelect";
import FormNumberInput from "@/components/form/FormNumberInput";
import FormTextarea from "@/components/form/FormTextarea";
import FormInput from "@/components/form/FormInput";

const schema = z.object({
  taskId: z.string(),
  alertTypeId: z.string(),
  dueDate: z.date(),
  daysBeforeAlert: z.number().nonnegative("Must be a non-negative number"),
  message: z.string().nonempty("Message is required"),
  alertTitle: z.string().nonempty("Alert Title is required"),
  users: z
    .array(
      z.object({
        id: z.string(),
        fullName: z.string(),
      })
    )
    .optional(), // `users` field is now optional
  snoozeUnit: z.nativeEnum(SnoozeUnit),
  alertFrom: z.nativeEnum(AlertFrom),
  workFlowId: z.string(),
  SnoozeDuration: z.number().nonnegative("Must be a non-negative number"),
});

type FormInputs = z.infer<typeof schema>;

type Props = {
  openAlertDialog: boolean;
  setOpenAlertDialog: (value: boolean) => void;
  taskId: string;
  workflowId: string;
};

export const AddGenericAlertDialog = ({
  openAlertDialog,
  setOpenAlertDialog,
  taskId,
  workflowId,
}: Props) => {
  const [addAlert, { isSuccess, isLoading }] = useAddAlertFortTaskMutation();

  const form = useForm<FormInputs>({
    resolver: zodResolver(schema),
    defaultValues: {
      taskId: taskId,
      alertTypeId: "",
      dueDate: new Date(),
      daysBeforeAlert: 1,
      message: "",
      snoozeUnit: SnoozeUnit.Days,
      alertFrom: AlertFrom.GenericTask,
      workFlowId: workflowId,
    },
  });

  const { data: alertTypes } = useGetAlertTypeQuery();

  const onSubmit = async (data: FormInputs) => {
    await addAlert({
      data: {
        taskId,
        alertFrom: data.alertFrom,
        workFlowId: workflowId,
        daysBeforeAlert: data.daysBeforeAlert,
        message: data.message,
        dueDate: data.dueDate,
        alertTypeId: data.alertTypeId,
        snoozeUnit: data.snoozeUnit,
        snoozeDuration: data.SnoozeDuration,
        alertTitle: data.alertTitle
      },
    });
    setOpenAlertDialog(false);
  };
  useEffect(() => {
    if (isSuccess) {
      setOpenAlertDialog(false);
    }
  }, [isSuccess, setOpenAlertDialog]);
  return (
    <DefaultDialog
      title={"Add Alert"}
      open={openAlertDialog}
      onClose={() => setOpenAlertDialog(false)}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-3"
      >
        <FormSelect
          label="Alert Type"
          control={form.control}
          name="alertTypeId"
          options={
            alertTypes?.map((option) => ({
              label: option.alertName,
              value: option.id,
            })) || []
          }
          required
        />

        <DateInput
          label="Due Date"
          onChange={(value) => {
            form.setValue("dueDate", value?.toDate() || new Date());
          }}
          value={dayjs(form.getValues("dueDate"))}
          disablePast
        />
        

        <FormNumberInput
          control={form.control}
          name="daysBeforeAlert"
          label="Days Before Alert"
          required
        />
        <FormSelect
          control={form.control}
          name="snoozeUnit"
          label="Snoot Unit"
          options={Object.values(SnoozeUnit).map((option) => ({
            label: option.toString(),
            value: option.toString(),
          }))}
          required
        />
        <FormNumberInput
          control={form.control}
          name="SnoozeDuration"
          label="Snooze Duration"
          required
        />
        <FormInput
          control={form.control}
          name="alertTitle"
          label="Alert Title"
          placeholder="Alert Title"
          required
        />
        <FormTextarea
          control={form.control}
          name="message"
          label="Message"
          placeholder="Type your message here."
          required
        />

        <div className="flex gap-2 justify-end mt-2">
          <CustomButton
            variant="danger"
            size="sm"
            onClick={() => setOpenAlertDialog(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="primary"
            size="sm"
            type="submit"
            isLoading={isLoading}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </DefaultDialog>
  );
};
