import { DefaultPage } from "@/components/shared/DefaultPage";
import { AddTaskDialog } from "../components/AddTaskDialog";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TaskListByStatus } from "../components/TaskListByStatus";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";
import { useGetOperationalTaskCountsQuery } from "../api/task-query";

export const TaskList = () => {
  const { session } = useAuth();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState("ongoing");
  const { data: countTask } = useGetOperationalTaskCountsQuery();

  const getTaskCount = (status: string) => {
    const task = countTask?.find((t) => t.status === status);
    return task ? `(${task.count})` : "(0)";
  };

  return (
    <DefaultPage
      headerTitle="Task List"
      headerActionButton={
        <div className="flex gap-3">
          <IsPermitted
            requiredPermissions={[
              UCM_Permissions.CancreatetasksOperationalTask,
            ]}
          >
            <Button onClick={() => setOpenDialog(true)}>
              {session?.userInfo.userType === UserType.Client
                ? "Request Task"
                : "Add Task"}
            </Button>
          </IsPermitted>
        </div>
      }
    >
      {openDialog && (
        <AddTaskDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
      <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="flex justify-start w-full gap-2">
          <TabsTrigger value="ongoing">
            On Going{getTaskCount("Ongoing")}
          </TabsTrigger>
          <TabsTrigger value="non-started">
            Non Started{getTaskCount("Submitted")}
          </TabsTrigger>
          <TabsTrigger value="completed" className="text-green-700">
            Completed{getTaskCount("Finished")}
          </TabsTrigger>
          <TabsTrigger value="Cancelled" className="text-red-300">
            Cancelled{getTaskCount("Canceled")}
          </TabsTrigger>
          <TabsTrigger value="Suspended" className="text-yellow-500">
            Suspended{getTaskCount("Suspended")}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="ongoing">
          <TaskListByStatus status="ongoing" />
        </TabsContent>
        <TabsContent value="non-started">
          <TaskListByStatus status="Submitted" />
        </TabsContent>
        <TabsContent value="completed">
          <TaskListByStatus status="Finished" />
        </TabsContent>
        <TabsContent value="Cancelled">
          <TaskListByStatus status="Canceled" />
        </TabsContent>
        <TabsContent value="Suspended">
          <TaskListByStatus status="Suspended" />
        </TabsContent>
      </Tabs>
    </DefaultPage>
  );
};
