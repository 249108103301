import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { PagedResponse } from "@/model/pagination-response";
import { ILead } from "../model/lead";

export const leadsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeads: builder.query<PagedResponse<ILead>, QueryRequest>({
      query: (params) => ({
        url:"/clients/SearchPotentialClients",
        method: "GET",
        params: params.data
      }),
      providesTags: ["Leads"], // Provides a tag for caching
    }),
    getLeadDetail: builder.query<ILead, QueryRequest>({
      query: (params) => ({
        url:"/clients/GetClient",
        method: "GET",
        params: params.data
      }),
      providesTags: ["Leads"], // Provides a tag for caching
    }),
    createLead: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/clients",
        method: "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Leads"],
    }),
    updateLead: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/clients/update",
        method: "PUT",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Leads"],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useCreateLeadMutation,
  useUpdateLeadMutation,
  useGetLeadDetailQuery
} = leadsApi;
