import { useState } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import {
  format,
  addMonths,
  subMonths,
  setMonth,
  setYear,
  getYear,
  startOfMonth,
} from "date-fns";

interface FormDatePickerProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  rules?: RegisterOptions<T>;
  required?: boolean;
}

const FormDatePicker = <T extends FieldValues>({
  name,
  control,
  label,
  placeholder = "Select a date",
  rules,
  required,
}: FormDatePickerProps<T>) => {
  const [open, setOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<Date>(startOfMonth(new Date()));
  const [selectingMonthYear, setSelectingMonthYear] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const months = Array.from({ length: 12 }, (_, i) => format(setMonth(new Date(), i), "MMMM"));
  const years = Array.from({ length: 20 }, (_, i) => getYear(new Date()) - 10 + i); // Adjust the range as needed

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setOpen(false);
  };

  return (
    <div className="mt-4">
      {label && (
        <Label
          htmlFor={name}
          className="text-left block text-sm font-medium text-gray-700"
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </Label>
      )}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <div>
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between w-full",
                      !selectedDate && "text-muted-foreground"
                    )}
                  >
                    {selectedDate
                      ? format(selectedDate, "MMMM d, yyyy") // Format the selected date
                      : placeholder}
                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 z-9999 w-full max-w-sm">
                  <div className="p-4">
                    {/* Header with Month and Year Selectors */}
                    <div className="flex items-center justify-between mb-4">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <div
                        className="flex items-center space-x-2 cursor-pointer"
                        onClick={() => setSelectingMonthYear(!selectingMonthYear)}
                      >
                        <span className="text-lg font-medium">
                          {format(currentMonth, "MMMM yyyy")}
                        </span>
                        <ChevronDown className="h-4 w-4" />
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
                      >
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>

                    {/* Vertical Scroll List for Month and Year Selector */}
                    {selectingMonthYear ? (
                      <div className="flex space-x-4">
                        {/* Month Selector - Vertical Scroll */}
                        <div className="w-1/2 pr-2 max-h-60 overflow-y-auto">
                          <h3 className="font-semibold mb-2">Select Month</h3>
                          <div className="space-y-2">
                            {months.map((month, index) => (
                              <button
                                key={month}
                                onClick={() => {
                                  setCurrentMonth(setMonth(currentMonth, index));
                                  setSelectingMonthYear(false);
                                }}
                                className={cn(
                                  "w-full text-left p-2 text-sm rounded-md hover:bg-blue-500 hover:text-white",
                                  format(currentMonth, "MMMM") === month
                                    ? "bg-blue-500 text-white"
                                    : "bg-gray-100"
                                )}
                              >
                                {month}
                              </button>
                            ))}
                          </div>
                        </div>

                        {/* Year Selector - Vertical Scroll */}
                        <div className="w-1/2 pl-2 max-h-60 overflow-y-auto">
                          <h3 className="font-semibold mb-2">Select Year</h3>
                          <div className="space-y-2">
                            {years.map((year) => (
                              <button
                                key={year}
                                onClick={() => {
                                  setCurrentMonth(setYear(currentMonth, year));
                                  setSelectingMonthYear(false);
                                }}
                                className={cn(
                                  "w-full text-left p-2 text-sm rounded-md hover:bg-blue-500 hover:text-white",
                                  getYear(currentMonth) === year
                                    ? "bg-blue-500 text-white"
                                    : "bg-gray-100"
                                )}
                              >
                                {year}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      // Calendar Grid for Days
                      <div className="grid grid-cols-7 gap-1 text-center">
                        {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                          <span key={day} className="text-sm font-medium text-gray-500">
                            {day}
                          </span>
                        ))}
                        {Array.from({ length: 42 }).map((_, i) => {
                          const firstDayOfMonth = startOfMonth(currentMonth).getDay();
                          const dayOfMonth = i - firstDayOfMonth + 1;
                          const currentDate = new Date(
                            currentMonth.getFullYear(),
                            currentMonth.getMonth(),
                            dayOfMonth
                          );

                          const isDisabled =
                            dayOfMonth <= 0 ||
                            dayOfMonth > new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();

                          return (
                            <button
                              key={i}
                              disabled={isDisabled}
                              onClick={() => {
                                if (!isDisabled) {
                                  handleDateChange(currentDate);
                                  field.onChange(currentDate);
                                }
                              }}
                              className={cn(
                                "w-8 h-8 rounded-md text-sm",
                                isDisabled
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "hover:bg-blue-500 hover:text-white",
                                !isDisabled &&
                                  currentDate.toDateString() ===
                                    (selectedDate?.toDateString() || "")
                                  ? "bg-blue-500 text-white"
                                  : ""
                              )}
                            >
                              {!isDisabled && dayOfMonth}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </PopoverContent>
              </Popover>
              {error && (
                <p className="text-red-600 text-sm mt-1">{error.message}</p>
              )}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default FormDatePicker;
