import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import React, { SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NumericFormat } from "react-number-format";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormField,
  FormControl,
  FormLabel,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { useLazyGetCustomersQuery } from "@/features/customers/api/customer-api";
import { useAuth } from "@/lib/auth";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronDown, Check } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { ExpenseTo } from "../model/expense";
import { useAddExpenseMutation } from "../api/expense-api";
import FormDatePicker from "@/components/shared/CustomDatePicker";

// Define Zod schema for form validation
const expenseSchema = z
  .object({
    amount: z.string().nonempty("Expense amount is required").refine((value) => {
      const numValue = parseFloat(value);
      return numValue > 0;
    }, {
      message: "Amount must be greater than 0",
    }),
    clientId: z.string().optional(),
    payTo: z.string().optional(),
    expenseTo: z.nativeEnum(ExpenseTo, {
      errorMap: () => ({ message: "Expense type is required" }),
    }),
    description: z.string().optional(),
    dueDate: z.date(),
  })
  .superRefine((data, ctx) => {
    if (!data.clientId && !data.payTo) {
      ctx.addIssue({
        path: ["payTo"],
        message: "Payee is required if client is not selected",
        code: "custom",
      });
    }
  });

type ExpenseFormValues = z.infer<typeof expenseSchema>;

interface AddExpenseProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  isPaid: boolean
}

const AddExpense = ({ openForm, setOpenForm, isPaid }: AddExpenseProps) => {
  const { session } = useAuth();
  const [openClientSelect, setOpenClientSelect] = useState(false);
  const [collectExpense, { isLoading, isSuccess }] = useAddExpenseMutation();
  const [getCustomers, { data: customerList }] = useLazyGetCustomersQuery();

  const form = useForm<ExpenseFormValues>({
    resolver: zodResolver(expenseSchema),
  });

  const fetchCustomers = (query = "") => {
    getCustomers({
      url: "/clients/SearchClients",
      method: "GET",
      data: {
        organizationId: session?.userInfo.organizationId,
        pageNumber: 1,
        pageSize: 100000,
        clientName: query,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      setOpenForm(false);
      Notify("success", "Expense added successfully!");
    }
  }, [isSuccess]);

  useEffect(() => {
    fetchCustomers();
  }, [session?.userInfo.organizationId]);

  const onSubmit = async (data: ExpenseFormValues) => {
    await collectExpense({
      data: {
        amount: data.amount,
        clientId: data.clientId,
        payTo: data.payTo,
        expenseTo: data.expenseTo,
        remark: data.description,
        isPaid: isPaid,
        dueDate: data.dueDate,
      }
    });
  };

  return (
    <DefaultDialog
      title="Add Expense"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          {/* Client Selection */}
          <div className="flex flex-col gap-3">
            <FormLabel>Customer</FormLabel>
            <Controller
              name="clientId"
              control={form.control}
              render={({ field }) => (
                <Popover open={openClientSelect} onOpenChange={setOpenClientSelect}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "justify-between",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value
                        ? customerList?.list?.find((c) => c.id === field.value)
                            ?.clientName
                        : "Select Client"}
                      <ChevronDown className="ml-2 h-4 w-4" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-full p-0 z-99999">
                    <Command>
                      <CommandInput
                        placeholder="Search Client"
                        onInput={(e) =>
                          fetchCustomers((e.target as HTMLInputElement).value)
                        }
                      />
                      <CommandList>
                        <CommandEmpty>No Client found.</CommandEmpty>
                        <CommandGroup>
                          {customerList?.list?.map((client) => (
                            <CommandItem
                              key={client.id}
                              value={client.id}
                              onSelect={() => {
                                field.onChange(client.id);
                                setOpenClientSelect(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  client.id === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {client.clientName}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              )}
            />
          </div>

          {/* Payee Field (conditionally shown) */}
          {!form.watch("clientId") && (
            <FormField
              name="payTo"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pay To<span className="text-red-500 ml-1">*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Enter Payee"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {/* Expense Type Selection */}
          <FormField
            name="expenseTo"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Expense To<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Expense Type" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {Object.values(ExpenseTo).map((type) => (
                        <SelectItem key={type} value={type}>
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Expense Amount Field */}
          <FormField
            name="amount"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Amount<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <NumericFormat
                    {...field}
                    placeholder="Amount"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    onValueChange={(values) => field.onChange(values.value)}
                    customInput={Input}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Description Field */}
          <FormField
            name="description"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    placeholder="Expense Description"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormDatePicker
            name="dueDate"
            control={form.control}
            label="Start date"
            placeholder="Choose a date"
            rules={{ required: "Date is required" }}
            />
          {/* <FormField
            name="dueDate"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Due Date<span className="text-red-500 ml-1">*</span></FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          "pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value
                          ? format(field.value, "PPP")
                          : "Pick a date"}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent align="start" className="w-auto p-0 z-99999">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          /> */}

          {/* Submit Button */}
          <CustomButton
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            className="w-full"
          >
            Submit Expense
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default AddExpense;
