export const UCM_Permissions = {
  Administrator: "Administrator",
  AbeltoviewAlert: "AbeltoviewAlert",
  AbeltoaddAlert: "AbeltoaddAlert",
  AbeltodeleteAlert: "AbeltodeleteAlert",
  AbletoeditAlert: "AbletoeditAlert",
  CaneditalertsettingsAlert: "CaneditalertsettingsAlert",
  CancloseCompleteanalertAlert: "CancloseCompleteanalertAlert",
  CanviewcustomersCustomer: "CanviewcustomersCustomer",
  CancreatecustomersCustomer: "CancreatecustomersCustomer",
  CaneditcustomerinfoCustomer: "CaneditcustomerinfoCustomer",
  CanviewcustomerdetailsCustomer: "CanviewcustomerdetailsCustomer",
  Canupdatestatus: "Canupdatestatus",
  CanviewExpense: "CanviewExpense",
  CanaddExpense: "CanaddExpense",
  CanupdatestatusExpense: "CanupdatestatusExpense",
  CanviewdashboardFinance: "CanviewdashboardFinance",
  EditpaymenttypesFinance: "EditpaymenttypesFinance",
  CanedittasksGenericTask: "CanedittasksGenericTask",
  CanupdatevalueGenericTask: "CanupdatevalueGenericTask",
  CancompleteclosesuspendtaskGenericTask: "CancompleteclosesuspendtaskGenericTask",
  CandeletetasksGenericTask: "CandeletetasksGenericTask",
  Canviewgenerictasks: "Canviewgenerictasks",
  Cancreategenerictasksanditsactivities: "Cancreategenerictasksanditsactivities",
  Cancreateacticitiesingenerictasks: "Cancreateacticitiesingenerictasks",
  Canviewleads: "Canviewleads",
  Cancreateleads: "Cancreateleads",
  Caneditleadinfo: "Caneditleadinfo",
  Canviewleaddetails: "Canviewleaddetails",
  CanviewoperationaltasksOperationalTask: "CanviewoperationaltasksOperationalTask",
  CancreatetasksOperationalTask: "CancreatetasksOperationalTask",
  CanedittasksOperationalTask: "CanedittasksOperationalTask",
  CanchoosetonextstepinaworkflowOperationalTask: "CanchoosetonextstepinaworkflowOperationalTask",
  CangobackandeditcompletedworkflowsOperationalTask: "CangobackandeditcompletedworkflowsOperationalTask",
  CansuspendcanceltasksOperationalTask: "CansuspendcanceltasksOperationalTask",
  CanviewhistoryOperationalTask: "CanviewhistoryOperationalTask",
  CanviewpaymentsOperationalTask: "CanviewpaymentsOperationalTask",
  CanviewworkflowsettingsOperationalworkflowsettings: "CanviewworkflowsettingsOperationalworkflowsettings",
  CaneditworkflowsettingsOperationalworkflowsettings: "CaneditworkflowsettingsOperationalworkflowsettings",
  CancreateworkflowOperationalworkflowsettings: "CancreateworkflowOperationalworkflowsettings",
  CancreateparamtersOperationalworkflowsettings: "CancreateparamtersOperationalworkflowsettings",
  CaneditworkflowOperationalworkflowsettings: "CaneditworkflowOperationalworkflowsettings",
  CaneditparamteresOperationalworkflowsettings: "CaneditparamteresOperationalworkflowsettings",
  CandeleteworkflowOperationalworkflowsettings: "CandeleteworkflowOperationalworkflowsettings",
  CanactivatedeactivateworkflowsOperationalworkflowsettings: "CanactivatedeactivateworkflowsOperationalworkflowsettings",
  CanwritenotesinmessagesPipeline: "CanwritenotesinmessagesPipeline",
  CanviewhistoryPipeline: "CanviewhistoryPipeline",
  CanviewPipeline: "CanviewPipeline",
  CancreatePipeline: "CancreatePipeline",
  CaneditPipeline: "CaneditPipeline",
  CanmovetonextstepPipeline: "CanmovetonextstepPipeline",
  CancancelPipeline: "CancancelPipeline",
  CansuspendPipeline: "CansuspendPipeline",
  CanviewpipelinesettingsPipelinesetting: "CanviewpipelinesettingsPipelinesetting",
  CaneditworkflowsettingsPipelinesetting: "CaneditworkflowsettingsPipelinesetting",
  CancreateworkflowPipelinesettings: "CancreateworkflowPipelinesettings",
  CancreateparamtersPipelinesettings: "CancreateparamtersPipelinesettings",
  CaneditworkflowPipelinesettings: "CaneditworkflowPipelinesettings",
  CaneditparamteresPipelinesettings: "CaneditparamteresPipelinesettings",
  CandeleteworkflowPipelinesettings: "CandeleteworkflowPipelinesettings",
  CanactivatedeactivateworkflowsPipelinesettings: "CanactivatedeactivateworkflowsPipelinesettings",
  CanviewRevenue: "CanviewRevenue",
  CanaddRevenue: "CanaddRevenue",
  CanupdatestatusRevenue: "CanupdatestatusRevenue",
  CanviewrolesettingsRole: "CanviewrolesettingsRole",
  CancreaterolesRole: "CancreaterolesRole",
  CanseteditrolepermissionsRole: "CanseteditrolepermissionsRole",
  CanmanageroleusersRole: "CanmanageroleusersRole",
  CandeleterolesRole: "CandeleterolesRole",
  CanviewdashboardSalesDashboard: "CanviewdashboardSalesDashboard",
  Canviewsetting: "Canviewsetting",
  CancreateandeditsectorscasesSetting: "CancreateandeditsectorscasesSetting",
  CandeletesectorsandcasesSetting: "CandeletesectorsandcasesSetting",
  CanedittrafficsourcesSetting: "CanedittrafficsourcesSetting",
  CanviewsettingSetting: "CanviewsettingSetting",
  CanviewtendersettingsTendersettingsTendersettings: "CanviewtendersettingsTendersettingsTendersettings",
  CaneditworkflowsettingsTendersettings: "CaneditworkflowsettingsTendersettings",
  CancreateworkflowTendersettings: "CancreateworkflowTendersettings",
  CancreateparamtersTendersettings: "CancreateparamtersTendersettings",
  CaneditworkflowTendersettings: "CaneditworkflowTendersettings",
  CaneditparamteresTendersettings: "CaneditparamteresTendersettings",
  CandeleteworkflowTendersettings: "CandeleteworkflowTendersettings",
  CanactivatedeactivateworkflowsTendersettings: "CanactivatedeactivateworkflowsTendersettings",
  CanwritenotesinmessagesTenderWorkflow: "CanwritenotesinmessagesTenderWorkflow",
  CanviewTenderWorkflow: "CanviewTenderWorkflow",
  CancreateTenderWorkflow: "CancreateTenderWorkflow",
  CaneditTenderWorkflow: "CaneditTenderWorkflow",
  CanmovetonextstepTenderWorkflow: "CanmovetonextstepTenderWorkflow",
  CancancelTenderWorkflow: "CancancelTenderWorkflow",
  CansuspendTenderWorkflow: "CansuspendTenderWorkflow",
  CanviewhistoryTenderWorkflow: "CanviewhistoryTenderWorkflow",
  CanviewusersettingsUsers: "CanviewusersettingsUsers",
  CancreatestaffaccountUsers: "CancreatestaffaccountUsers",
  CancreateclientaccountUsers: "CancreateclientaccountUsers",
  CandeleteupdateuserUsers: "CandeleteupdateuserUsers",
  CanreactivatePipeline: "CanreactivatePipeline",
  CanrevisePipeline: "CanrevisePipeline",
  CanactivatedeactivateUser: "CanactivatedeactivateUser",
  CanmanageclientUser: "CanmanageclientUser",
  CanEditrolesRole: "CanEditrolesRole",
  CanDuplicateWorkflowOperationalworkflowsettings: "CanDuplicateWorkflowOperationalworkflowsettings",
  CanEditWorkflowOperationalworkflowsettings: "CanEditWorkflowOperationalworkflowsettings",
  canaddparamtersOperationalworkflowsettings: "canaddparamtersOperationalworkflowsettings",
  CancreatesubworkflowOperationalworkflowsettings: "CancreatesubworkflowOperationalworkflowsettings",
  CanduplicateworkflowPiplineworkflowsettings: "CanduplicateworkflowPiplineworkflowsettings",
  canaddparamtersPiplineworkflowsettings: "canaddparamtersPiplineworkflowsettings",
  caneditparamtersPiplineworkflowsettings: "caneditparamtersPiplineworkflowsettings",
  candeleteparamtersPiplineworkflowsettings: "candeleteparamtersPiplineworkflowsettings",
  canaddsource: "canaddsource",
  caneditsource: "caneditsource",
  candeletesource: "candeletesource",
  cancreatesector: "cancreatesector",
  canDuplicatesector: "canDuplicatesector",
  canEditsector: "canEditsector",
  canDeletesector: "canDeletesector",
  CanVoidRevenue: "CanVoidRevenue",
  CanCollecteRevenue: "CanCollecteRevenue",
  CanVoidExpense: "CanVoidExpense",
  CanPayExpense: "CanPayExpense",
  SuspendOperationalTask: "SuspendOperationalTask",
  BackToRevisionOperationalTask: "BackToRevisionOperationalTask",
  CloseOperationalTask: "CloseOperationalTask",
  CompleteOperationalTask: "CompleteOperationalTask",
  CancelOperationalTask: "CancelOperationalTask",
  ReactivateOperationalTask: "ReactivateOperationalTask",
  SuspendGenericTask: "SuspendGenericTask",
  BackToRevisionGenericTask: "BackToRevisionGenericTask",
  CloseGenericTask: "CloseGenericTask",
  CompleteGenericTask: "CompleteGenericTask",
  CancelGenericTask: "CancelGenericTask",
  ReactivateGenericTask: "ReactivateGenericTask",
  Dashboard: "Dashboard",
  SMSEmail: "SMSEmail",
  CompanyProfile: "CompanyProfile",
  CanIssueInvoice: "CanIssueInvoice",
  CanmovetonextstepOeprationalTask: "CanmovetonextstepOeprationalTask",
  DeletepaymenttypesFinance: "DeletepaymenttypesFinance",
  AddpaymenttypesFinance: "AddpaymenttypesFinance",
  CandeletealertsettingsAlert: "CandeletealertsettingsAlert",
  CancreatealertsettingsAlert: "CancreatealertsettingsAlert"
};
