import CustomButton from "@/components/shared/Button/CustomButton";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ITaskNote } from "@/features/task/models/Task";
import { formatTimestamp } from "@/lib/utils";
import { differenceInDays, parseISO } from "date-fns";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { useUpdateGenericTaskNoteMutation } from "../api/generic-task-command";

type Props = {
  message: ITaskNote;
  userId: string;
};

const GenericTaskMessageContent = ({ message, userId }: Props) => {
  const [editNote, setEditNote] = useState(false);
  const [editedContent, setEditedContent] = useState(message.content);

  const [update, { isLoading }] = useUpdateGenericTaskNoteMutation();

  const handleSave = async () => {
    await update({
      data: {
        content: editedContent,
        taskId: message.taskId,
        id: message.id,
      },
    });
    setEditNote(false);
  };

  const handleCancel = () => {
    setEditedContent(message.content);
    setEditNote(false);
  };
  console.log(message);

  const isEditable = () => {
    if (message.userId !== userId) return false;
    const diffInDays = differenceInDays(
      new Date(),
      parseISO(message.timeStamp)
    );
    if (diffInDays > 2) return false;
    return true;
  };

  return (
    <div
      key={message.id}
      className={`flex ${
        message.userId === userId ? "justify-end" : "justify-start"
      }`}
    >
      {editNote ? (
        <div className="mr-3">
          <Textarea
            id={message.id}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="text-black bg-gray-200 border-none my-2 w-100 overflow-auto border-2"
          />
          <div className="flex justify-end gap-2">
            <Button
              onClick={handleCancel}
              className="h-8 rounded-md px-3"
              aria-label="Cancel edit"
              variant={"destructive"}
            >
              Cancel
            </Button>
            <CustomButton
              aria-label="Save edit"
              className="h-8 rounded-md px-3"
              variant="primary"
              isLoading={isLoading}
              onClick={handleSave}
            >
              Save
            </CustomButton>
          </div>
        </div>
      ) : (
        <div
          className={`p-3 rounded-lg shadow-sm ${
            message.userId === userId
              ? "bg-blue-700 text-white"
              : "bg-gray-200 text-black"
          } max-w-3xl`}
        >
          {message.userId !== userId && (
            <div className="flex items-center mb-1">
              <p className="text-xs font-medium text-gray-700 mr-2">
                {message.userName}
              </p>
              <p className="text-xs text-gray-500">
                {formatTimestamp(message.timeStamp)}
              </p>
            </div>
          )}
          {message.userId === userId && (
            <div className="flex justify-end items-center mb-1">
              <p className="text-xs text-gray-300">
                {formatTimestamp(message.timeStamp)}
              </p>
            </div>
          )}
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <p className="text-sm">{message.content}</p>
          </div>
          {isEditable() && (
            <Button
              onClick={() => setEditNote(true)}
              className=" text-white"
              aria-label="Edit message"
              size={"icon"}
            >
              <Pencil size={12} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default GenericTaskMessageContent;
