import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import DisplayTab from "./DisplayTab";
import PermissionsTab from "./PermissionsTab";
import Members from "./Members";
import { IRole } from "../models/Role";

interface RoleDetailProps {
  role: IRole;
}

const RoleDetail = ({
role
}: RoleDetailProps): JSX.Element => {

  return (
    <div className="py-4">
      {/* <h2 className="text-xl font-semibold mb-4">{role?.roleName}</h2> */}
      <Tabs defaultValue="display">
        <TabsList>
          <TabsTrigger value="display">Display</TabsTrigger>
          <TabsTrigger value="permissions">Permissions</TabsTrigger>
          <TabsTrigger value="members">Manage Members</TabsTrigger>
        </TabsList>

        {/* Display Tab */}
        <TabsContent value="display" className="mt-4">
          <DisplayTab roleId={role.id} />
        </TabsContent>

        {/* Permissions Tab */}
        <TabsContent value="permissions" className="mt-4">
          <PermissionsTab role={role} />
        </TabsContent>

        {/* Manage Members Tab */}
        <TabsContent value="members" className="mt-4">
          <Members roleId={role.id} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default RoleDetail;
