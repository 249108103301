import { Button } from "@/components/ui/button";

import { useEffect, useRef, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { useLazyGetTaskNoteByTaskIdQuery } from "../api/task-query";
import { useAuth } from "@/lib/auth";
import { useSaveTaskNoteMutation } from "../api/task-command";
import TaskMessageContent from "./TaskMessageContent";

type Props = {
  taskId: string;
};
// const formatTimestamp = (isoString: string): string => {
//   const date = parseISO(isoString);
//   return formatDistanceToNow(date, { addSuffix: true });
// };

export const TaskMessaging = ({ taskId }: Props) => {
  // const [messages, setMessages] = useState<Message[]>(initialMessages);
  const { session } = useAuth();
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [fetchNotes, { data: messages = [] }] =
    useLazyGetTaskNoteByTaskIdQuery();
  const [saveNote, { data: note, isLoading, isSuccess }] =
    useSaveTaskNoteMutation();

  useEffect(() => {
    // Function to fetch notes
    const getNotes = () => {
      fetchNotes(taskId);
    };

    // Fetch immediately when the component mounts
    getNotes();

    // Set up the interval to fetch every 5 seconds (5000 ms)
    const intervalId = setInterval(getNotes, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchNotes, taskId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;
    await saveNote({
      data: {
        content: newMessage,
        taskId: taskId,
      },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setNewMessage("");
    }
  }, [isSuccess]);
  console.log(note);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex flex-col px-10">
      <div className="h-[60vh]">
        <ScrollArea className="h-full flex-1 overflow-auto p-4">
          <div className="flex flex-col space-y-2">
            {messages?.length > 0 ? (
              messages.map(
                (message) =>
                  session?.userInfo.id && (
                    <TaskMessageContent
                      key={message.id}
                      message={message}
                      userId={session?.userInfo.id}
                    />
                  )
              )
            ) : (
              <div className="text-center text-gray-500">
                No messages available
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>
      </div>
      <div className="flex gap-2 p-4 border-t">
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          className="flex-1"
          disabled={isLoading}
        />
        <Button onClick={handleSendMessage} type="button" disabled={isLoading}>
          Send
        </Button>
      </div>
    </div>
  );
};
