import React, { useEffect, useState } from "react";
import { getCurrentSession } from "../utilities";
import { UserType } from "@/model/SideBarMenu";
import { UCM_Permissions } from "../../../../permission";

type isPermittedProps = {
  requiredPermissions: string[];
  // userTypes: UserType[]
  children: React.ReactNode;
};

const IsPermitted = ({ requiredPermissions, children }: isPermittedProps): JSX.Element => {
  const [authorized, setAuthorized] = useState<boolean>(false);
  const session = getCurrentSession();

  useEffect(() => {
    const userPermissions: string[] = session?.userInfo?.permissions || [];
    const hasAllowAllPermission = userPermissions.includes(UCM_Permissions.Administrator);

    // If the user has "allow all" permission, they are authorized
    if (hasAllowAllPermission) {
        setAuthorized(true);
        return;
    }

    // Check if the user has all the required permissions
    const isAuthorized = requiredPermissions.every((permission) => userPermissions.includes(permission));

    setAuthorized(isAuthorized);
}, [requiredPermissions, session?.userInfo?.permissions, session?.userInfo?.userType]);


  return <>{authorized && <>{children}</>}</>;
};

export default IsPermitted;

export const IsPermittedWithoutChildren = (requiredPermissions: string[], userTypes: UserType[]): boolean => {
  const [authorized, setAuthorized] = useState<boolean>(false);

  const session = getCurrentSession();

  useEffect(() => {
    const userPermissions: string[] =
      session?.userInfo.permissions?.map((permission: string) => permission) ||
      [];

      const isAuthorized =
      session?.userInfo.userType === UserType.Root ||
      (requiredPermissions.every((permission) => userPermissions?.includes(permission)) && userTypes.includes(session?.userInfo.userType))

    setAuthorized(isAuthorized);
  }, [requiredPermissions, userTypes, session.userInfo.permission, session.userInfo.userType]);
  return authorized;
};
