import { Button } from '@/components/ui/button';
import { Edit2 } from 'lucide-react';
import { IUser, UserType } from '../models/User';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { Chip } from '@mui/material';
import { Assignment } from '@mui/icons-material';
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";

interface UserHeaderProps {
  user: IUser
  onEditClick?: () => void;
  onEditRoleClick?: () => void;
  onEditClientClick?: () => void;
  onActivateDeActivate?: () => void;
}


export const UserHeader = ({ 
    user, 
    onEditClick, 
    onEditRoleClick,
    onEditClientClick,
    onActivateDeActivate
}: UserHeaderProps) => {

  return (
    <div className="bg-white p-4 shadow rounded-lg">
            <div className="flex flex-col justify-between gap-4">
                <div className='flex flex-row justify-between'>
                    <div className="flex-1 flex flex-col gap-2">
                        <p className="text-xl font-semibold">{user?.fullName}</p>
                        <div className='flex gap-4'>
                            <div className="flex gap-2 items-center">
                                <p>User Name</p>
                                <p className="bg-secondary px-2 rounded-full">{user?.username}</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <p>User Type</p>
                                <p className="bg-secondary px-2 rounded-full">{user?.userType}</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <p>Status</p>
                                <p className="bg-secondary px-2 rounded-full">{user?.isActive ? "Active": "InActive"}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2'>
                            <IsPermitted requiredPermissions={[UCM_Permissions.CandeleteupdateuserUsers]}>
                                <Button
                                    onClick={onEditClick}
                                    variant={"outline"}
                                    className="text-primary border-primary border-2"
                                >
                                    EDIT <Edit2 />
                                </Button>
                            </IsPermitted>
                       
                            <IsPermitted requiredPermissions={[UCM_Permissions.CanactivatedeactivateUser]}>
                                <Button
                                    onClick={onActivateDeActivate}
                                    variant={"outline"}
                                    className={`${user.isActive ? 
                                        "text-[#FF0000] border-[#FF0000] border-2 hover:bg-[#FF0000] hover:text-[#FFFFFF]" :
                                        "text-[#4caf50] border-[#4caf50] border-2 hover:bg-[#4caf50] hover:text-[#FFFFFF]"
                                    }
                                        `}
                                >
                                    {user.isActive ? "DeActivate" : "Activate"}
                                </Button>
                            </IsPermitted>
                    </div>
                </div>
                <Separator className='w-full border'/>
                <div className='flex flex-col lg:flex-row gap-4'>
                    <div className="flex flex-col">
                        <p className="text-sm font-light">Email</p>
                        <p className="text-lg font-medium">{user?.email}</p>
                    </div>
                    <Separator className='w-full border lg:hidden'/>
                    <div className="flex flex-col">
                        <p className="text-sm font-light">Phone Number</p>
                        <p className="text-lg font-medium">{user?.phoneNumber}</p>
                    </div>
                </div>
                <Separator className='w-full border'/>
                {
                    user.userType !== UserType.Client && <>
                     <div className="flex gap-2 items-center mt-2">
                        <p className="text-sm font-light">Roles</p>
                        <div className="flex flex-wrap gap-2 items-center">
                        {user?.roles?.map((role, index) => (
                            <Chip key={index} label={role.roleName} />
                        ))}
                            <IsPermitted requiredPermissions={[UCM_Permissions.CanmanageroleusersRole]}>
                                <Button
                                    onClick={onEditRoleClick}
                                    variant={"outline"}
                                    className="text-primary flex gap-2 items-center"
                                >
                                    <Edit2 className='h-4 w-4'/> Edit Role 
                                </Button>
                            </IsPermitted>
                      
                        </div>
                    </div>
                    <div className="flex gap-2 items-center mt-2">
                        <p className="text-sm font-light">Clients</p>
                        <div className="flex flex-wrap gap-2 items-center">
                        {user?.clients?.map((client) => (
                            <Chip key={client.clientId} label={client.clientName} />
                        ))}
                            <IsPermitted requiredPermissions={[UCM_Permissions.CanmanageclientUser]}>
                                <Button
                                    onClick={onEditClientClick}
                                    variant={"outline"}
                                    className="text-primary flex gap-2 items-center"
                                >
                                    <Assignment className='h-4 w-4'/> Assign Clients
                                </Button>
                            </IsPermitted>
                      
                        </div>
                    </div>
                    </>
                }
               
            </div>
    </div>
  );
};
