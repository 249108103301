import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { IUser } from "../models/User";
import { useDeleteUserMutation, useGetUsersQuery } from "../api/user-api";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { Notify } from "@/components/shared/Notification/notify";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";

export function StaffUserList() {
  const { session } = useAuth()
  const navigate = useNavigate()
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const { data: usersList, isLoading: isUserLoading, isFetching } = useGetUsersQuery({
    data: {
      organizationId: session?.userInfo.organizationId,
      userType: UserType.Staff
    }
  });

  const [deleteUser, { isLoading, isSuccess }] = useDeleteUserMutation()

  const handleDeleteuser = async (id: string) => {
    await deleteUser(id)
  }

  // Define columns for MaterialReactTable
  const userListColumns: MRT_ColumnDef<IUser>[] = [
    {
      accessorKey: "username",
      header: "User Name",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone Number",
    },
    {
      accessorKey: "userType",
      header: "User Type"
    },
    {
      id: "actions",
      header: "Action",
      Cell: ({row}) => (
        <IsPermitted requiredPermissions={[UCM_Permissions.CandeleteupdateuserUsers]}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={(e) => {
              e.stopPropagation(); 
              setSelectedUser(row.original);
              setOpenConfirmDeleteModal(true);
            }}
            className="border-transparent"
          >
            <Trash2 size={14} className="mr-2" color="red" />
          </Button>
        </IsPermitted>
      ),
    },
  ];

  useEffect(() => {
    if(isSuccess) {
      Notify("success", "User Deleted Successfully!")
    }
  }, [isSuccess])
  return (
    <>
      <MaterialReactTable
      columns={userListColumns}
      data={usersList ?? []}
      enablePagination
      state={{isLoading: isUserLoading || isFetching}}
      muiTableBodyRowProps={(row) => ({
        onClick: () => {
          navigate(`/settings/users/${row.row.original.id}`)
        },
        sx: {
          cursor: 'pointer',
        }
      })}
    />
    {
      openConfirmDeleteModal && selectedUser && (
        <ConfirmModal
        title="Delete User"
        message="Are you sure you want delete this user"
        modalOpen={openConfirmDeleteModal}
        setModalOpen={(value) => setOpenConfirmDeleteModal(value)}
        type="warning"
        loading={isLoading}
        onCancel={() => setOpenConfirmDeleteModal(false)}
        onOk={() => handleDeleteuser(selectedUser.id)}
        />
      )
    }
    </>
  );
}
