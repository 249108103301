import { useEffect, useState } from "react";
import {
  useGetUsersByRoleIdQuery,
  useRemoveRoleMemberMutation,
} from "../api/user-api";
import { DataTable } from "@/components/shared/DataTable/DataTable";
import { IUser } from "../models/User";
import { ColumnDef } from "@tanstack/react-table";
import { Trash } from "lucide-react";
import { Button } from "@/components/ui/button";
import { AddMemberDialog } from "./AddMemberDialog";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";


interface RoleDetailProps {
  roleId: string;
}

const Members = ({ roleId }: RoleDetailProps) => {
  const {
    data: roleUsers = [],
    refetch,
    isFetching,
    isLoading: isFetchingUserLoading,
  } = useGetUsersByRoleIdQuery({
    data: { id: roleId },
  });

  useEffect(() => {
    refetch();
  }, [refetch, roleId]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const columns: ColumnDef<IUser>[] = [
    {
      accessorKey: "fullName",
      header: "Name",
    },
    {
      accessorKey: "username",
      header: "Username",
    },
    {
      id: "remove",
      header: "Actions",
      cell: ({ row }) => (
        <IsPermitted requiredPermissions={[UCM_Permissions.CanmanageroleusersRole]}>
          <button
            onClick={() => {
              setSelectedUser(row.original);
              setOpenConfirmModal(true);
            }}
            className="text-red-500"
          >
            <Trash />
          </button>
        </IsPermitted>
      ),
    },
  ];

  const [
    removeRoleMember,
    { isLoading, isSuccess: removingMemberSuccess, isError },
  ] = useRemoveRoleMemberMutation();

  const [openAddMemberDialog, setOpenAddMemberDialog] =
    useState<boolean>(false);

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const handleRemoveUser = () => {
    if (selectedUser) {
      removeRoleMember({
        data: {
          roleId: roleId,
          userId: selectedUser.id,
        },
      });
    }
  };
  
  useEffect(() => {
    if (removingMemberSuccess) {
      refetch();
      setOpenConfirmModal(false);
      setSelectedUser(null);
    }
  }, [removingMemberSuccess, refetch]);
  useEffect(() => {
    if (isError) {
      setOpenConfirmModal(false);
      setSelectedUser(null);
    }
  }, [isError]);

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <input
          type="text"
          placeholder="Search members..."
          className="border p-2"
        />
        <IsPermitted requiredPermissions={[UCM_Permissions.CanmanageroleusersRole]}>
          <Button
            onClick={() => {
              setOpenAddMemberDialog(true);
            }}
            className="ml-4 p-2 bg-blue-500 text-white"
          >
            Add Member
          </Button>
        </IsPermitted>
      </div>

      <DataTable
        columns={columns}
        data={roleUsers as IUser[]}
        enableSorting={true}
        enableColumnFilters={true}
        enablePagination={false}
        loading={isFetchingUserLoading || isFetching}
      />
      <AddMemberDialog
        open={openAddMemberDialog}
        onClose={() => setOpenAddMemberDialog(false)}
        roleId={roleId}
        members={roleUsers.map((user) => user.id) as unknown as string[]}
      />
      <ConfirmModal
        message="Are you sure you?"
        modalOpen={openConfirmModal}
        onOk={handleRemoveUser}
        setModalOpen={() => console.log("Close")}
        title="Remove User From Role"
        type="warning"
        onCancel={() => {
          setOpenConfirmModal(false);
          setSelectedUser(null);
        }}
        loading={isLoading}
      />
    </div>
  );
};

export default Members;
