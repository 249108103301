import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import {
  IGenericTask,
  IGenericTaskDetail,
  IGenericTaskHistory,
  IGenericTaskPayment,
} from "../model/GenericTask";
import { IUser } from "@/features/users/models/User";
import { PagedResponse } from "@/model/pagination-response";
import { ITaskNote, TaskCount } from "@/features/task/models/Task";

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getGenericTasks: builder.query<IGenericTask[], void>({
      query: () => ({
        url: `/genericTasks`,
        method: "GET",
      }),
    }),
    getGenericTaskDetailsByTaskId: builder.query<IGenericTaskDetail, string>({
      query: (id) => ({
        url: `/genericTaskDetails/${id}`,
        method: "GET",
      }),
      providesTags: ["GenericTask", "GenericTaskDetail"],
    }),
    getTaskHistoryByTaskId: builder.query<IGenericTaskHistory[], string>({
      query: (id) => ({
        url: `/taskHistories/GetTaskHistoryByTaskId`,
        method: "GET",
        params: { taskId: id },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
    getUsers: builder.query<IUser[], QueryRequest>({
      query: (params) => ({
        url: "/users/SearchUsers",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Users"], // Provides a tag for caching
    }),
    addGenericTask: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/genericTasks",
        method: "POST",
        data: { ...params.data },
      }),
      invalidatesTags: ["GenericTask"],
    }),

    searchGenerics: builder.query<PagedResponse<IGenericTask>, QueryRequest>({
      query: (params) => ({
        url: "/genericTasks/SearchGenericTasks",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["GenericTask"], // Provides a tag for caching
    }),

    getGenericTaskHistoryById: builder.query<
      IGenericTaskHistory[],
      QueryRequest
    >({
      query: (params) => ({
        url: `/genericTaskHistories/${params.data.id}`,
        method: "GET",
        // params: { ...params.data },
      }),
      providesTags: ["GenericTask"], // Provides a tag for caching
    }),
    getGenericTaskNoteByTaskId: builder.query<ITaskNote[], string>({
      query: (id) => ({
        url: `/genericTaskNotes/${id}`,
        method: "GET",
        params: { taskId: id },
      }),
      providesTags: ["Task", "TaskDetail", "TaskNote", "GenericTask"],
    }),
    getGenericTaskPayementByTaskId: builder.query<
      IGenericTaskPayment[],
      string
    >({
      query: (id) => ({
        url: `/clientFinance/${id}`,
        method: "GET",
      }),
      providesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),
    addGenericTaskPayement: builder.mutation<string, FormData>({
      query: (params) => ({
        url: `/clientFinance`,
        method: "POST",
        data: params,
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),
    updateGenericTaskPayement: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/${params.url}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: [
        "GenericTask",
        "GenericTaskDetail",
        "GenericTaskPayment",
      ],
    }),
    getGenericTaskCounts: builder.query<TaskCount[], void>({
      query: (taskId) => ({
        url: `/genericTasks/GetGenericTaskCounts`,
        method: "GET",
        params: { taskId: taskId },
      }),
    }),
  }),
});

export const {
  useGetTaskHistoryByTaskIdQuery,
  useGetUsersQuery,
  useAddGenericTaskMutation,
  useGetGenericTasksQuery,
  useGetGenericTaskDetailsByTaskIdQuery,
  useSearchGenericsQuery,
  useGetGenericTaskHistoryByIdQuery,
  useGetGenericTaskNoteByTaskIdQuery,
  useLazyGetGenericTaskNoteByTaskIdQuery,
  useAddGenericTaskPayementMutation,
  useGetGenericTaskPayementByTaskIdQuery,
  useUpdateGenericTaskPayementMutation,
  useGetGenericTaskCountsQuery,
} = taskApi;
