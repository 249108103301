import React, { useState, useRef } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";

type ChartType = "bar" | "line" | "pie";

// Define the type before using it
const chartThemes = {
  oceanBreeze: [
    "#3498DB", // Sky Blue
    "#2ECC71", // Emerald Green
    "#5DADE2", // Light Sky Blue
    "#1ABC9C", // Turquoise Teal
    "#58D68D", // Pale Green
    "#2980B9", // Ocean Blue
  ],
  sunsetGlow: [
    "#E74C3C", // Cinnabar Red
    "#F39C12", // Golden Orange
    "#F1C40F", // Golden Yellow
    "#F1948A", // Light Coral
    "#F5B041", // Sunset Orange
    "#F9E79F", // Light Yellow
  ],
  naturalSerenity: [
    "#2ECC71", // Emerald Green
    "#1ABC9C", // Turquoise Teal
    "#58D68D", // Pale Green
    "#48C9B0", // Light Aqua
    "#27AE60", // Forest Green
    "#F9E79F", // Light Yellow
  ],
  royalElegance: [
    "#9B59B6", // Amethyst Purple
    "#F39C12", // Golden Orange
    "#FF6F61", // Coral Pink
    "#D7BDE2", // Lavender Purple
    "#FADBD8", // Light Coral Pink
    "#D2B4DE", // Lilac Purple
  ],
  forestWild: [
    "#27AE60", // Forest Green
    "#16A085", // Sea Green
    "#8E44AD", // Brownish Purple
    "#F1C40F", // Golden Yellow
    "#E67E22", // Carrot Orange
    "#C0392B", // Tomato Red
  ],
  vibrantSpectrum: [
    "#3498DB", // Sky Blue
    "#E74C3C", // Cinnabar Red
    "#2ECC71", // Emerald Green
    "#F39C12", // Golden Orange
    "#9B59B6", // Amethyst Purple
    "#F1C40F", // Golden Yellow
  ],
  pastelDelight: [
    "#A9CCE3", // Light Blue
    "#A3E4D7", // Light Green
    "#FADBD8", // Light Coral Pink
    "#D7BDE2", // Lavender Purple
    "#F9E79F", // Light Yellow
    "#F5B041", // Sunset Orange
  ],
  brightFuture: [
    "#00FF00", // Neon Green
    "#00FFFF", // Cyan
    "#FF0000", // Crimson Red
    "#FFFF00", // Bright Yellow
    "#FF00FF", // Fuchsia Pink
    "#FFA500", // Bright Orange
  ],
  professionalClassic: [
    "#2980B9", // Ocean Blue
    "#BDC3C7", // Light Gray
    "#34495E", // Charcoal Black
    "#FFFFFF", // Pure White
    "#95A5A6", // Silver
    "#E5E8E8", // Light Gray
  ],
  desertMirage: [
    "#F4E1D2", // Light Beige
    "#D5A6BD", // Light Sand
    "#D35400", // Burnt Orange
    "#F1C40F", // Golden Yellow
    "#E67E22", // Carrot Orange
    "#F5B041", // Sunset Brown
  ]
};

// Then use the type
type ChartTheme = keyof typeof chartThemes;

interface GenericChartProperty {
  color: ChartTheme;
  chartType: ChartType;
  xAxisKey: string | null;
  yAxisKey: string | null;
}

interface GenericChartProps {
  data: any[];
  isLoading: boolean;
  isError: boolean;
  title: string;
  defaultChartType?: ChartType;
  id: string;
}

const GenericChart: React.FC<GenericChartProps> = ({
  data,
  isLoading,
  isError,
  title,
  defaultChartType = "pie",
  id,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);
  const [propertyState, setPropertyState] = useState<GenericChartProperty>(
    JSON.parse(localStorage.getItem(`chartProperty-${id}`) || "{}") ?? {
      color: "oceanBreeze",
      chartType: defaultChartType,
      xAxisKey: null,
      yAxisKey: null,
    }
  );

  React.useEffect(() => {
    const storedProperty = localStorage.getItem(`chartProperty-${id}`);
    if (storedProperty) {
      setPropertyState(JSON.parse(storedProperty));
    }
  }, [id]);

  React.useEffect(() => {
    localStorage.setItem(
      `chartProperty-${id}`,
      JSON.stringify({
        color: propertyState.color ?? "#8884d8",
        chartType: propertyState.chartType ?? defaultChartType,
        xAxisKey: propertyState.xAxisKey,
        yAxisKey: propertyState.yAxisKey,
      })
    );
  }, [propertyState, id, defaultChartType]);

  // Function to find and exclude any ID key dynamically
  const filterIdKey = (keys: string[]) => {
    return keys.filter((key) => !key.toLowerCase().includes("id"));
  };

  React.useEffect(() => {
    const handleSetAxisKeys = () => {
      if (data.length > 0) {
        const keys = filterIdKey(Object.keys(data[0]));
        if (!propertyState.xAxisKey || !propertyState.yAxisKey) {
          setPropertyState({
            ...propertyState,
            xAxisKey: keys[0],
            yAxisKey: keys[1],
          });
        }
      }
    };

    handleSetAxisKeys();
  }, [data]);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      chartRef.current?.requestFullscreen();
    }
  };

  React.useEffect(() => {
    const handleFullscreenChange = () =>
      setIsFullscreen(Boolean(document.fullscreenElement));
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  if (!propertyState.xAxisKey || !propertyState.yAxisKey) {
    return null;
  }

  const renderChart = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={300}
        >
          <Typography variant="body2" marginLeft={2}>
            Loading data...
          </Typography>
        </Box>
      );
    }

    if (isError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height={300}
          color="error.main"
        >
          <Typography variant="h6">Error Loading Data</Typography>
        </Box>
      );
    }

    if (!data || data.length === 0) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height={300}
        >
          <Typography variant="h6">No Data Available</Typography>
        </Box>
      );
    }

    const chartMargin = { top: 40, right: 40, bottom: 100, left: 60 };

    switch (propertyState.chartType) {
      case "bar":
        return (
          <ResponsiveBar
            data={data}
            keys={[propertyState.xAxisKey ?? ""]}
            indexBy={propertyState.xAxisKey ?? ""}
            colors={chartThemes[propertyState.color as keyof typeof chartThemes]}
            margin={chartMargin}
            padding={0.3}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: propertyState.xAxisKey,
              legendPosition: "middle",
              legendOffset: 50,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: propertyState.yAxisKey,
              legendPosition: "middle",
              legendOffset: -50,
            }}
          />
        );
      case "line":
        return (
          <ResponsiveLine
            data={[
              {
                id: propertyState.yAxisKey ?? "",
                data: data.map((d) => ({
                  x: d[propertyState.xAxisKey ?? ""],
                  y: d[propertyState.yAxisKey ?? ""],
                })),
              },
            ]}
            colors={chartThemes[propertyState.color as keyof typeof chartThemes]}
            margin={chartMargin}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: propertyState.xAxisKey,
              legendPosition: "middle",
              legendOffset: 50,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: propertyState.yAxisKey,
              legendPosition: "middle",
              legendOffset: -50,
            }}
            lineWidth={3}
            pointSize={10}
            pointColor={propertyState.color}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
          />
        );
      case "pie":
        return (
          <ResponsivePie
            data={data.map((d) => ({
              id: d[propertyState.xAxisKey ?? ""],
              label: d[propertyState.xAxisKey ?? ""],
              value: d[propertyState.yAxisKey ?? ""],
              color: propertyState.color,
            }))}
            colors={chartThemes[propertyState.color as keyof typeof chartThemes]}
            margin={{ top: 20, right: 30, bottom: 50, left: 40 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            valueFormat={(value) => `${value}`}
          />
        );
    }
  };
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={3}
      bgcolor="#f5f5f5"
      borderRadius={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        marginBottom={2}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
        >
          <Typography variant="h6" component="h2" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={toggleFullscreen} aria-label="fullscreen">
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" gap={2} width="100%" flexWrap="nowrap">
          <Select
            value={propertyState.chartType}
            onChange={(e) =>
              setPropertyState({
                ...propertyState,
                chartType: e.target.value as ChartType,
              })
            }
            size="small"
            variant="outlined"
            style={{ width: "calc(25% - 16px)" }}
          >
            <MenuItem value="bar">Bar</MenuItem>
            <MenuItem value="line">Line</MenuItem>
            <MenuItem value="pie">Pie</MenuItem>
          </Select>
          <Select
            value={propertyState.xAxisKey}
            onChange={(e) =>
              setPropertyState({ ...propertyState, xAxisKey: e.target.value })
            }
            size="small"
            variant="outlined"
            style={{ width: "calc(25% - 16px)" }}
            displayEmpty
            renderValue={(selected) => (
              <Typography
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {selected || "X-Axis"}
              </Typography>
            )}
          >
            {filterIdKey(Object.keys(data[0] || {})).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={propertyState.yAxisKey}
            onChange={(e) =>
              setPropertyState({ ...propertyState, yAxisKey: e.target.value })
            }
            size="small"
            variant="outlined"
            style={{ width: "calc(25% - 16px)" }}
            displayEmpty
            renderValue={(selected) => (
              <Typography
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {selected || "Y-Axis"}
              </Typography>
            )}
          >
            {filterIdKey(Object.keys(data[0] || {})).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
          <Select
            size="small"
            variant="outlined"
            style={{ width: "calc(25% - 16px)" }}
            onChange={(e) => setPropertyState({ 
              ...propertyState, 
              color: e.target.value as ChartTheme 
            })}
            defaultValue={propertyState.color}
          >
            {(Object.keys(chartThemes) as ChartTheme[]).map((theme) => (
              <MenuItem
                key={theme}
                value={theme}
                style={{
                  background: `linear-gradient(to right, ${chartThemes[theme][0]}, ${chartThemes[theme][1]})`,
                  color: "#fff",
                }}
              >
                {theme}
              </MenuItem>
            ))}
          </Select>
        </Box>

      </Box>
      <Box
        ref={chartRef}
        width="100%"
        height={isFullscreen ? "100vh" : 400}
        className={isFullscreen ? "fullscreen" : ""}
      >
        {renderChart()}
      </Box>

      <style>{`
        .fullscreen {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: white;
          z-index: 9999;
          padding: 20px;
        }
      `}</style>
    </Box>
  );
};

export default GenericChart;
