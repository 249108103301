import { type ClassValue, clsx } from "clsx";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getCurrentSession(): any | null {
  const sessionString = localStorage.getItem("session");
  if (sessionString) {
    return JSON.parse(sessionString);
  }
  return null;
}

export function timeAgo(date: Date | string): string {
  const parsedDate = typeof date === "string" ? new Date(date) : date;

  if (isNaN(parsedDate.getTime())) {
    throw new Error("Invalid date");
  }

  const seconds = Math.floor(
    (new Date().getTime() - parsedDate.getTime()) / 1000
  );

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1)
    return interval === 1 ? "1 year ago" : interval + " years ago";

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1)
    return interval === 1 ? "1 month ago" : interval + " months ago";

  interval = Math.floor(seconds / 86400);
  if (interval >= 1)
    return interval === 1 ? "1 day ago" : interval + " days ago";

  interval = Math.floor(seconds / 3600);
  if (interval >= 1)
    return interval === 1 ? "1 hour ago" : interval + " hours ago";

  interval = Math.floor(seconds / 60);
  if (interval >= 1)
    return interval === 1 ? "1 minute ago" : interval + " minutes ago";

  return seconds < 5 ? "Just now" : seconds + " seconds ago";
}

export const titleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export function toTitleCase(str: string): string {
  return str
    .replace(/([A-Z])/g, " $1") // Insert space before capital letters
    .replace(/^./, (match) => match.toUpperCase()) // Capitalize the first letter
    .trim() // Remove leading/trailing spaces
    .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
}

export function formatToETB(amount: number): string {
  return new Intl.NumberFormat("en-ET", {
    style: "currency",
    currency: "ETB",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}
export const formatTimestamp = (isoString: string): string => {
  const date = parseISO(isoString);
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;
  const diffInDays = Math.floor(diffInSeconds / (60 * 60 * 24));

  // const locale = localeMap[localeStr] || enUS;

  if (diffInDays < 7) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else {
    const relativeTime = formatDistanceToNow(date, { addSuffix: true });
    const exactDate = format(date, "MMM dd, yyyy");
    return `${relativeTime} • ${exactDate}`;
  }
};
