import { useGetRolesQuery } from '../api/user-api';
import { useState } from 'react';
import RoleTabs from './RoleTabs';
import RoleList from './RoleList';
import { IRole } from '../models/Role';

const RolePage = () => {
    const { data: roles = [], isLoading } = useGetRolesQuery();
    const [ selectedRole, setSelectedRole ] = useState<IRole | null>(null)
    console.log(roles)
    return (
        <div>
           {
            selectedRole ? <RoleTabs roles={roles} selectedRole={selectedRole} setSelectedRole={setSelectedRole}/>
            : <RoleList roles={roles} isFetching={isLoading} setSelectedRoleOne={setSelectedRole}/>
          }
        </div>
    )
}

export default RolePage