import { Button } from "@/components/ui/button";
import { Pencil, Trash2 } from "lucide-react";
import { IconButton } from "@mui/material";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useEffect, useState } from "react";
import { DefaultPage } from "@/components/shared/DefaultPage";
import { Notify } from "@/components/shared/Notification/notify";
import { ISource } from "../model/source";
import EditSource from "../component/EditSource";
import AddSource from "../component/AddSource";
import { useDeleteSourceMutation, useGetSourceQuery } from "../api/api";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../../permission";


export function Source() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedSource, setSelectedSource] = useState<ISource | null>(null)

  const { data: sourceList, isLoading: isSourceFetchLoading}= useGetSourceQuery({})
  const [deleteSource, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess}] = useDeleteSourceMutation()

  const sourceListColumns: MRT_ColumnDef<ISource>[] = [
    {
      accessorKey: "sourceName",
      header: "Source",
    },
    {
        accessorKey: "description",
        header: "Description"
    },
    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      Cell: ({ row }) => {
        return (
          <div className="flex gap-4 items-center">
            <IsPermitted requiredPermissions={[UCM_Permissions.caneditsource]}>
              <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setSelectedSource(row.original);
                setOpenEditDialog(true);
              }}
              >
              <Pencil size={14} className="mr-2" />
              Edit
              </Button>
            </IsPermitted>
            <IsPermitted requiredPermissions={[UCM_Permissions.candeletesource]}>
              <IconButton
              onClick={() => {
                setSelectedSource(row.original);
                setOpenDeleteModal(true);
              }}
              >
              <Trash2 color="red" />
              </IconButton>
            </IsPermitted>
          </div>
        );
      },
    },
  ];

  const handleDeleteCase = async () => {
    await deleteSource({
      data: {
        id: selectedSource?.id
      }
    })
  };

  useEffect(() => {
    if(isDeleteSuccess) {
      setOpenDeleteModal(false)
      Notify("success", "Alert Type Deleted")
    }
  }, [isDeleteSuccess])
 
  return (
    <>
    <DefaultPage
    headerTitle="Source"
    headerActionButton={
      <IsPermitted requiredPermissions={[UCM_Permissions.canaddsource]}>
        <Button onClick={() => setOpenDialog(true)}>
          Add New Source
        </Button>
      </IsPermitted>
    }
    >
      <MaterialReactTable
      columns={sourceListColumns}
      data={sourceList ?? []}
      state={{ density: "compact", isLoading: isSourceFetchLoading }}
      />
    </DefaultPage>
    {
      openDialog && <AddSource openForm={openDialog} setOpenForm={setOpenDialog}/>
    }
    {
      (openEditDialog && selectedSource)&& <EditSource openForm={openEditDialog} setOpenForm={setOpenEditDialog} source={selectedSource}/>
    }
    {openDeleteDialog && selectedSource && (
      <ConfirmModal
        message="Are you Sure you want to delete this source?"
        title="Delete Source"
        modalOpen={openDeleteDialog}
        setModalOpen={() => {setOpenDeleteModal(false)}}
        type="error"
        customOkText="Delete"
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
        onOk={() => handleDeleteCase()}
        loading={isDeleteLoading}
      />
    )}
    </>
  );
}