import { useParams } from "react-router-dom";
import { useState } from "react";
import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";
import { useGetGenericTaskDetailsByTaskIdQuery } from "../api/generic-task-api";
import GenericTaskDetail from "../components/GenericTaskDetail";
import { GenericTaskHistory } from "../components/GenericTaskHistory";
import { GenericTaskMessaging } from "../components/GenericTaskMessaging";
import { GenericTaskPayment } from "../components/GenericTaskPayment";
import { GenericTaskAlertNotificationList } from "../components/TaskAlertNotificationList";

export const GenericTaskDetailPage = () => {
  const { id } = useParams();
  const { data: taskDetail, isLoading: isFetchingTaskLoading } =
    useGetGenericTaskDetailsByTaskIdQuery(id ?? "");
  const [selectedTab, setSelectedTab] = useState("detail");

  if (isFetchingTaskLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DefaultPageHeader
        headerTitle={`Task Detail`}
        breadcrumbsLinks={[
          {
            name: "Home",
            url: "/",
          },
          {
            name: " Generic Task List",
            url: "/task/generic-task",
          },
          {
            name: "Generic Task Detail",
            url: "#",
          },
        ]}
        headerActionButton={
          <CircularPercentageIndicator
            value={taskDetail?.completionRate ?? 100}
            size={60}
          />
        }
      />
      <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="grid w-100 grid-cols-5">
          <TabsTrigger value="detail">Detail</TabsTrigger>
          <TabsTrigger value="message">Messages</TabsTrigger>
          <TabsTrigger value="payment">Payment</TabsTrigger>
          <TabsTrigger value="alerts">Alerts</TabsTrigger>
          <TabsTrigger value="history">History</TabsTrigger>
        </TabsList>
        <TabsContent value="detail">
          {!taskDetail && <div>Loading...</div>}
          {taskDetail && (
            <GenericTaskDetail
              taskDetail={taskDetail}
              showEditButton={true}
              onEditButtonClicked={() => {}}
            />
          )}
        </TabsContent>
        <TabsContent value="history">
          {taskDetail?.id && (
            <>
              <GenericTaskHistory taskId={taskDetail.id} />
            </>
          )}
        </TabsContent>
        <TabsContent value="message">
          {taskDetail?.id && (
            <>
              <GenericTaskMessaging
                taskId={taskDetail.id}
                taskDetail={taskDetail}
              />
            </>
          )}
        </TabsContent>
        <TabsContent value="payment">
          {taskDetail?.id && (
            <>
              <GenericTaskPayment taskDetail={taskDetail} />
            </>
          )}
        </TabsContent>
        <TabsContent value="alerts">
          {taskDetail?.id && (
            <GenericTaskAlertNotificationList taskId={taskDetail.id} />
          )}
        </TabsContent>
      </Tabs>
    </>
  );
};
