import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IGenericTask } from "../model/GenericTask";
import { useSearchGenericsQuery } from "../api/generic-task-api";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";
import { Badge } from "@/components/ui/badge";

const taskListColumns: MRT_ColumnDef<IGenericTask>[] = [
  {
    accessorKey: "taskReference",
    header: "Reference Number",
  },
  {
    accessorKey: "taskName",
    header: "Task Name",
  },
  {
    accessorKey: "startDate",
    header: "StartDate",
    Cell: ({ row }) => {
      return new Date(row.original.startDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    Cell: ({ row }) => {
      if (!row.original.endDate) {
        return "N/A";
      }
      return new Date(row.original.endDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "taskStatus",
    header: "Status",
  },
  {
    accessorKey: "completionRate",
    header: "Progress",
    Cell: ({ row }) => {
      return (
        <>
          <CircularPercentageIndicator value={row.original.completionRate} />
        </>
      );
    },
  },
  {
    header: "User",
    Cell: ({ row }) => {
      return (
        row.original.users?.map((user) => (
          <Badge key={user.id} className="mx-0.5">
            {user.fullName}
          </Badge>
        )) ?? "N/A"
      );
    },
  },
];

type Props = {
  status:
    | "ongoing"
    | "Started"
    | "Finished"
    | "Submitted"
    | "Canceled"
    | "Suspended";
};

export const GenericTaskListByStatus = ({ status }: Props) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const [globalFilter, setGlobalFilter] = useState("");

  const { data: tasks, isLoading } = useSearchGenericsQuery({
    data: {
      taskStatus: status,
      orderDirection: "desc",
    },
  });

  return (
    <MaterialReactTable
      columns={taskListColumns}
      data={tasks?.list ?? []}
      enablePagination
      manualPagination
      rowCount={tasks?.count ?? 0} // Total number of records (if server returns it)
      state={{ pagination, density: "compact", globalFilter, isLoading }}
      onPaginationChange={setPagination}
      initialState={{
        pagination: { pageIndex: 1, pageSize: 50 },
      }}
      // enableColumnFilters={false}
      manualFiltering
      onGlobalFilterChange={setGlobalFilter} //hoist internal global state to your state
      muiTableBodyRowProps={({ row }) => {
        let rowColor = '';
        
        const endDate = row.original.endDate ? new Date(row.original.endDate) : null;
        if (endDate) {
          const currentDate = new Date();
          const diffTime = endDate.getTime() - currentDate.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays <= 0) {
            rowColor = 'rgba(220, 38, 38, 0.2)'; // lighter red for overdue
          } else if (diffDays <= 5) {
            rowColor = 'rgba(252, 165, 165, 0.2)'; // very light red for warning
          }
        }

        return {
          onClick: () => {
            navigate(`/task/generic-task/${row.original.id}`);
          },
          sx: {
            cursor: "pointer",
            backgroundColor: rowColor,
            '&:hover': {
              backgroundColor: rowColor ? `${rowColor.replace(/[\d.]+\)$/, '0.3)')} !important` : undefined,
            },
          },
        };
      }}
    />
  );
};
