import { UCM_Permissions } from  '../../permission'
export enum UserType {
  Client = "Client",
  Staff = "Staff",
  Root = "Root",
}

// Update the Menu type to accept an array of UserType values
export type Menu = {
  name: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  path: string;
  permissions: string[];
  allowedUserType: UserType[]; // Now accepts multiple user types
  parentLinked?: boolean;
  child?: Menu[];
};

export function filterMenusByPermissions(
  menus: Menu[],
  permissionKeys: string[]
): Menu[] {
  return menus
    .filter((menu) => {
      // Check if the user has "allow all" permission
      const hasAllowAllPermission = permissionKeys.includes(UCM_Permissions.Administrator);

      // If the user has "allow all" permission, include all menus
      if (hasAllowAllPermission) {
        return true;
      }

      // If the menu has no permissions, allow it
      const hasRequiredPermissions =
        menu.permissions.length === 0 || // No permissions required
        menu.permissions.some((permission) => permissionKeys.includes(permission));

      return hasRequiredPermissions;
    })
    .map((menu) => {
      // If the menu has child menus, filter them recursively
      if (menu.child) {
        const filteredChildMenus = filterMenusByPermissions(
          menu.child,
          permissionKeys
        );
        // Only return the parent menu if it has valid children
        return filteredChildMenus.length > 0
          ? { ...menu, child: filteredChildMenus }
          : null;
      }
      return menu; // No children, return the menu
    })
    .filter((menu): menu is Menu => menu !== null); // Filter out null menus
}
