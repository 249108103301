import { useDeleteRoleMutation } from '../api/user-api';
import { IRole } from '../models/Role';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import IsPermitted from '@/lib/auth/components/IsPermitted';
import { UCM_Permissions } from '../../../../permission';
import { Button } from '@/components/ui/button';
import { Trash2 } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';
import { SetStateAction, useEffect, useState } from 'react';
import { Person } from '@mui/icons-material';
import { DefaultPage } from '@/components/shared/DefaultPage';
import { ConfirmModal } from '@/components/shared/modals/ConfirmationDialog';
import { Notify } from '@/components/shared/Notification/notify';


interface RoleTabsProps {
  roles: IRole[],
  setSelectedRoleOne: React.Dispatch<SetStateAction<IRole | null>>,
  isFetching: boolean
}

const RoleList = ({roles, setSelectedRoleOne, isFetching}: RoleTabsProps) => {
  const [isDeleteRoleOpen, setIsDeleteRoleOpen] = useState<boolean>(false)
  const [DeleteRole, {isLoading, isSuccess}] = useDeleteRoleMutation()
  const [selectedRole, setSelectedRole] = useState<IRole | null>(null)

  const deleteRole = async(roleId: string) => {
    await DeleteRole(roleId)
  }

  useEffect(() => {
    if(isSuccess) {
      setIsDeleteRoleOpen(false)
      setSelectedRole(null)
      Notify("success", "Role deleted successfully!")
    }
  }, [isSuccess])

  const roleListColumns: MRT_ColumnDef<IRole>[] = [
    {
      accessorKey: "roleName",
      header: "Role",
    },
    {
      accessorKey: "members",
      header: "Members",
      Cell({row}) {
        return (
          <div className='flex items-center gap-1'>
            <p className='text-lg'>{row.original.membersCount}</p><Person/>
          </div>
        )
      },
    },
    {
      id: "actions",
      header: "Action",
      Cell: ({row}) => (
        <IsPermitted requiredPermissions={[UCM_Permissions.CandeleterolesRole]}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRole(row.original)
              setIsDeleteRoleOpen(true)
            }}
            disabled={row.original.roleName === "Owner"}
            className="border-transparent"
          >
            <Trash2 size={14} className="mr-2" color="red" />
          </Button>
        </IsPermitted>
      ),
    },
  ];

  return (
    <>
      <DefaultPage
      headerTitle='Role List'
      >
          <MaterialReactTable
          columns={roleListColumns}
          data={roles}
          enablePagination
          state={{isLoading: isFetching}}
          muiTableBodyRowProps={(row) => ({
              onClick: () => {
                setSelectedRoleOne(row.row.original)
              // navigate(`/roles/${row.row.original.id}`)
              },
              sx: {
              cursor: 'pointer',
              }
          })}
          />
      </DefaultPage>
      {
        selectedRole && isDeleteRoleOpen &&
        <ConfirmModal
        modalOpen={isDeleteRoleOpen}
        setModalOpen={() => setIsDeleteRoleOpen(false)}
        title='Delete Role'
        message='Are you want to delete this role?'
        type='warning'
        loading={isLoading}
        onCancel={() => setIsDeleteRoleOpen(false)}
        onOk={async() => await deleteRole(selectedRole.id)}
        />
      }
    </>
  )
}

export default RoleList