import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { useActivateTaskMutation } from "../api/task-command";
import { Textarea } from "@/components/ui/textarea";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useActivateSalesTaskMutation } from "@/features/sales/api/pipe-line-wf-command";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";

type Props = {
  taskId: string;
  isSalesWf?: boolean;
};

export const ReactivateTaskDialog = ({ taskId, isSalesWf }: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [note, setNote] = useState("");

  const [activateWorkflow, { isSuccess, isLoading }] =
    useActivateTaskMutation();

  const [
    activateSalesWorkflow,
    {
      isSuccess: isActivatingSalesWfSuccess,
      isLoading: isActivateSalesWfLoading,
    },
  ] = useActivateSalesTaskMutation();

  const handleCancelWorkflow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSalesWf) {
      await activateSalesWorkflow({
        data: {
          taskId: taskId,
          note: note,
        },
      });
      return;
    }
    await activateWorkflow({
      data: {
        taskId: taskId,
        note: note,
      },
    });
  };
  useEffect(() => {
    if (isSuccess || isActivatingSalesWfSuccess) {
      setOpenDialog(false);
    }
  }, [isActivatingSalesWfSuccess, isSuccess]);

  return (
    <div className="flex mt-3">
      <IsPermitted requiredPermissions={[UCM_Permissions.CanreactivatePipeline]}>
        <Button
          variant={"defaultPrimary"}
          onClick={() => setOpenDialog(true)}
          className=""
        >
          Reactivate
        </Button>
      </IsPermitted>
      <DefaultDialog
        title="Reactivate Workflow"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <form onSubmit={handleCancelWorkflow}>
          <div className="mt-3">
            <span className="m-1">Note</span>
            <Textarea
              placeholder="Add Note"
              className="mt-2"
              required
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <div className="flex justify-center mt-3">
              <CustomButton
                className="w-50 items-center"
                isLoading={isLoading || isActivateSalesWfLoading}
              >
                Submit
              </CustomButton>
            </div>
          </div>
        </form>
      </DefaultDialog>
    </div>
  );
};
