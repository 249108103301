import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { IWorkFlow } from "../../Case/models/workflow";
import { useGetWorkFlowByCaseTypeIdQuery } from "../../api/work-flow-api";
import WorkflowBody from "./WorkflowBody";
import { Button } from "@/components/ui/button";
import { useUpdateWorkOrderNoMutation } from "../../Case/api/case-api";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { Badge } from "@/components/ui/badge";
import { Copy, Pencil, Trash2 } from "lucide-react";
import { AddWorkFlowDialog } from "./AddWorkFlowDialog";
import {
  useDeleteWorkFlowMutation,
  useDuplicateWorkflowMutation,
} from "../../api/work-flow-command";
import { Typography } from "@mui/material";
import { CopyWorkflowDialog } from "./CopyWorkflowDialog";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../../permission";

type Props = {
  selectedCaseId: string;
};

export const WorkflowList = ({ selectedCaseId }: Props) => {
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkFlow | null>(
    null
  );
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDeleteConfirmModal, setDeleteOpenConfirmModal] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const {
    data: workflows,
    isLoading: isFetchingWorkFlowLoading,
    isFetching: isFetchingWorkFlow,
  } = useGetWorkFlowByCaseTypeIdQuery({ data: { caseTypeId: selectedCaseId } });

  const [initialData, setInitialData] = useState<IWorkFlow[]>([]);
  const [data, setData] = useState<IWorkFlow[]>([]);

  useEffect(() => {
    if (workflows) {
      setData(workflows);
      setInitialData(workflows);
    }
  }, [workflows]);

  const hasOrderChanged = useMemo(() => {
    if (initialData.length !== data.length) return false;
    return data.some((item, index) => item.id !== initialData[index].id);
  }, [data, initialData]);

  const [
    updateWorkflowOrderNo,
    { isLoading: isUpdateWfOrderLoading, isSuccess: isUpdateWfSuccess },
  ] = useUpdateWorkOrderNoMutation();
  const [
    deleteWorkflow,
    {
      isLoading: isDeleteWfLoading,
      isError: isDeleteWfError,
      isSuccess: isDeleteWfSuccess,
    },
  ] = useDeleteWorkFlowMutation();
  const [
    duplicateWorkflow,
    {
      isLoading: isLoadingDuplicatedWorkFlow,
      isSuccess: isSuccessDuplicateWorkFlow,
    },
  ] = useDuplicateWorkflowMutation();

  const handleDeleteWorkflow = async () => {
    if (selectedWorkflow) {
      await deleteWorkflow(selectedWorkflow.id);
    }
  };

  const handleDuplicateWorkflow = async (workflowId: string) => {
    await duplicateWorkflow({ data: { sourceWorkFlowId: workflowId } });
  };

  useEffect(() => {
    if (isDeleteWfError || isDeleteWfSuccess) {
      setSelectedWorkflow(null);
      setDeleteOpenConfirmModal(false);
    }
  }, [isDeleteWfError, isDeleteWfSuccess]);

  const handleSaveOrder = async () => {
    const orders = data.map((item, index) => ({ id: item.id, orderNo: index }));
    await updateWorkflowOrderNo({ data: orders });
    setInitialData([...data]);
  };

  useEffect(() => {
    if (isUpdateWfSuccess) {
      setOpenConfirmModal(false);
    }
  }, [isUpdateWfSuccess]);

  useEffect(() => {
    if (isSuccessDuplicateWorkFlow) {
      Notify("success", "Workflow Duplicated Successfully!");
    }
  }, [isSuccessDuplicateWorkFlow]);

  const columns = useMemo<MRT_ColumnDef<IWorkFlow>[]>(
    () => [
      {
        accessorKey: "workFlowName",
        header: "Step Name",
        Cell: ({ row }) => (
          <Typography fontWeight={"bold"}>
            {row.original.workFlowName}
          </Typography>
        ),
      },
      {
        accessorKey: "finalStatusName",
        header: "Final Status Name",
        Cell: ({ row }) => (
          <Typography fontWeight={"bold"}>
            {row.original.finalStatusName}
          </Typography>
        ),
      },
      {
        accessorKey: "notifyToClient",
        header: "Notify To Client",
        Cell: ({ row }) => (
          <Badge
            variant={row.original.notifyToClient ? "default" : "destructive"}
          >
            {row.original.notifyToClient ? "Yes" : "No"}
          </Badge>
        ),
      },
      {
        accessorKey: "notificationOption",
        header: "Notification Option",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-1">
              {row.original.notificationOption?.map((option) => (
                <Badge key={option} variant={"outline"}>
                  {option}
                </Badge>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ row }) => (
          <Badge variant={row.original.isActive ? "default" : "destructive"}>
            {row.original.isActive ? "Active" : "InActive"}
          </Badge>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { density: "compact" },
    state: { isLoading: isFetchingWorkFlowLoading || isFetchingWorkFlow },
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    renderDetailPanel: ({ row }) => <WorkflowBody workflow={row.original} />,
    enableRowOrdering: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        const draggingIndex = draggingRow?.index;
        const hoveredIndex = hoveredRow?.index;

        if (
          typeof draggingIndex === "number" &&
          typeof hoveredIndex === "number"
        ) {
          setData((prevData) => {
            const newData = [...prevData];
            const [movedItem] = newData.splice(draggingIndex, 1);
            newData.splice(hoveredIndex, 0, movedItem);
            return newData;
          });
        }
      },
    }),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <div className="flex gap-2 py-2">
        <IsPermitted
          requiredPermissions={[
            UCM_Permissions.CanDuplicateWorkflowOperationalworkflowsettings,
          ]}
        >
          <CustomButton
            size={"sm"}
            variant={"outline"}
            onClick={async () => {
              setSelectedWorkflow(row.original);
              await handleDuplicateWorkflow(row.original.id);
            }}
            isLoading={
              selectedWorkflow?.id === row.original.id &&
              isLoadingDuplicatedWorkFlow
            }
          >
            <Copy size={12} className="mr-2" />
            Duplicate
          </CustomButton>
        </IsPermitted>
        <IsPermitted
          requiredPermissions={[
            UCM_Permissions.CanEditWorkflowOperationalworkflowsettings,
          ]}
        >
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setOpenEditDialog(true);
              setSelectedWorkflow(row.original);
            }}
          >
            <Pencil size={12} className="mr-2" />
            Edit
          </Button>
        </IsPermitted>
        <IsPermitted
          requiredPermissions={[
            UCM_Permissions.CandeleteworkflowOperationalworkflowsettings,
          ]}
        >
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setDeleteOpenConfirmModal(true);
              setSelectedWorkflow(row.original);
            }}
            className="border-transparent"
          >
            <Trash2 size={14} className="mr-2" color="red" />
            Delete
          </Button>
        </IsPermitted>
      </div>
    ),
    positionActionsColumn: "last",
  });

  return (
    <>
      <div className="m-1 p-1">
        <MaterialReactTable table={table} />
        {hasOrderChanged && (
          <Button
            variant="defaultPrimary"
            size="sm"
            className="mt-3"
            onClick={() => setOpenConfirmModal(true)}
          >
            Save Workflow Order
          </Button>
        )}
        {openConfirmModal && (
          <ConfirmModal
            message="Are you Sure you want to change the order?"
            title="Order Workflow Steps"
            modalOpen
            setModalOpen={() => {}}
            type="warning"
            onCancel={() => setOpenConfirmModal(false)}
            onOk={handleSaveOrder}
            loading={isUpdateWfOrderLoading}
          />
        )}
        {openEditDialog && selectedWorkflow && (
          <AddWorkFlowDialog
            workflow={selectedWorkflow}
            caseTypeId={selectedCaseId}
            currentNumberOfWorkFlows={selectedWorkflow.workFlowStep}
            openDialog={openEditDialog}
            setOpenDialog={() => {
              setOpenEditDialog(false);
              setSelectedWorkflow(null);
            }}
            isEditing
          />
        )}
        {openDeleteConfirmModal && selectedWorkflow && (
          <ConfirmModal
            title="Delete Workflow"
            message="Are you Sure you want to Delete this workflow?"
            modalOpen
            setModalOpen={() => {}}
            type="error"
            onCancel={() => {
              setDeleteOpenConfirmModal(false);
              setSelectedWorkflow(null);
            }}
            onOk={handleDeleteWorkflow}
            loading={isDeleteWfLoading}
          />
        )}
      </div>
      {openCopyDialog && selectedWorkflow && (
        <CopyWorkflowDialog
          openDialog={openCopyDialog}
          setOpenDialog={setOpenCopyDialog}
          targetWorkflow={selectedWorkflow}
        />
      )}
    </>
  );
};
