import { IGenericTaskDetail, ITaskDetail } from "../model/GenericTask";
import { formatDate } from "date-fns";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Pencil, Plus, TriangleAlert } from "lucide-react";
import { ClientCard } from "@/features/customers/pages/ClientCard";
import LabelValue from "@/components/shared/LabelValue";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/features/users/models/User";
import { ChangeStatusOfTaskDialog } from "./ChangeStatusOfTaskDialog";
import { CancelledWorkflowBody } from "@/features/task/components/CancelledWorkflowBody";
import { AddGenericAlertDialog } from "./AddGenericAlertDialog";
import { AddGenericActivityDialog } from "./AddGenericActivityDialog";
import { UpdateGenericActivityValueDialog } from "./UpdateGenericActivityValueDialog";
import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../permission";

export type TaskDetailProps = {
  taskDetail: IGenericTaskDetail;
  showEditButton: boolean;
  onEditButtonClicked: () => void;
};

const GenericTaskDetail = ({ taskDetail }: TaskDetailProps) => {
  const columns = useMemo<MRT_ColumnDef<ITaskDetail>[]>(
    () => [
      {
        accessorKey: "user.fullName",
        header: "User",
      },
      {
        accessorKey: "user.phoneNumber",
        header: "Phone Number",
      },
      {
        accessorKey: "activity",
        header: "Activity",
      },
      {
        accessorKey: "createdAt",
        header: "Date",
        Cell: ({ row }) => {
          return formatDate(new Date(row.original.createdAt), "dd/MM/yyyy");
        },
      },
      {
        accessorKey: "parameterType",
        header: "Parameter Type",
        // TODO: add hover for paramter values if it is select or multiselect
      },
      {
        accessorKey: "value",
        header: "Value",
      },
    ],
    []
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<ITaskDetail | null>(
    null
  );
  const [editingState, setEditingState] = useState({
    isEditingActivity: false,
    isEditingParameter: false,
  });

  const { session } = useAuth();
  const [taskType, setTaskType] = useState<
    "Cancel" | "Close" | "Suspend" | "Activate" | null
  >(null);

  const table = useMaterialReactTable({
    columns,
    data: taskDetail.taskDetails ?? [],
    initialState: {
      density: "compact",
    },
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => {
      return (
        <div className="flex gap-1">
          <IsPermitted requiredPermissions={[UCM_Permissions.Cancreateacticitiesingenerictasks]}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setSelectedDetail(row.original);
                setEditingState((prev) => {
                  return { ...prev, isEditingActivity: true };
                });
                setOpenDialog(true);
              }}
            >
              <Pencil size={16} className="mr-2" />
              Edit Activity
            </Button>
          </IsPermitted>
          <IsPermitted requiredPermissions={[UCM_Permissions.CanupdatevalueGenericTask]}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setSelectedDetail(row.original);
                setOpenEditValueDialog(true);
              }}
            >
              Update Value
            </Button>
          </IsPermitted>
        </div>
      );
    },
  });

  const [openCloseSuspendDialog, setOpenCloseSuspendDialog] = useState(false);
  const wfClosedOrCanalled =
    taskDetail.taskStatus === "Closed" || taskDetail.taskStatus === "Canceled";
  const wfSuspendedOrCanalled =
    taskDetail.taskStatus === "Closed" ||
    taskDetail.taskStatus === "Canceled" ||
    taskDetail.taskStatus == "Suspended";
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openEditValueDialog, setOpenEditValueDialog] = useState(false);

  return (
    <div>
      {taskDetail.client && <ClientCard client={taskDetail.client} />}
      <div className="bg-white my-2 grid grid-cols-2 gap-4 shadow-sm rounded-lg p-4">
        <LabelValue label="Reference Number" value={taskDetail.taskReference} />
        <LabelValue label="Status" value={taskDetail.taskStatus} />
        <LabelValue label="Type" value={taskDetail.taskType} />
        {taskDetail.startDate && (
          <LabelValue
            label="Start Date"
            value={formatDate(taskDetail.startDate, "dd/MM/yyyy")}
          />
        )}
        {taskDetail.endDate && (
          <LabelValue
            label="End Date"
            value={formatDate(taskDetail.endDate, "dd/MM/yyyy")}
          />
        )}
        <LabelValue label="Description" value={taskDetail.description} />
      </div>
      <div className="m-1 p-1">
        <div className="flex justify-between items-center my-3">
          <p>Activities</p>
          {!wfSuspendedOrCanalled && (
            <IsPermitted requiredPermissions={[UCM_Permissions.Cancreateacticitiesingenerictasks]}>
              <Button onClick={() => setOpenDialog(true)}>
                <Plus size={20} />
                Add Activity
              </Button>
            </IsPermitted>
          )}
        </div>
        <div className="">
          <MaterialReactTable table={table} />
        </div>
      </div>
      {taskDetail.taskStatus == "Suspended" && (
        <div className="mt-3 flex flex-col rounded-2xl w-full bg-yellow-500 shadow-xl">
          <div className="flex flex-col p-8">
            <div className="text-2xl font-bold   text-[#374151] pb-6">
              Suspended
            </div>
            <div className=" text-lg   text-black">
              Current Workflow is suspend please reactivate or cancel to
              continue
            </div>
            <div className="flex justify-end pt-6"></div>
          </div>
        </div>
      )}
      {taskDetail.taskStatus == "BackToRevision" && (
        <div className="mt-3 flex flex-col rounded-2xl w-full bg-yellow-500 shadow-xl">
          <div className="flex flex-col p-8">
            <div className="text-2xl font-bold   text-[#374151] pb-6">
              Back To Revision
            </div>
            <div className=" text-lg   text-black">
              Current Workflow is Back To Revision please revise
            </div>
            <div className="flex justify-end pt-6">
              {/* <button class="bg-[#80840f] text-[#ffffff]  font-bold text-2xl  p-3 rounded-lg hover:bg-purple-800 active:scale-95 transition-transform transform">Reactivate</button> */}
            </div>
          </div>
        </div>
      )}
      {taskDetail.taskStatus == "Canceled" && <CancelledWorkflowBody />}

      {!wfClosedOrCanalled && (
        <div className="m-2">
          {session?.userInfo.userType !== UserType.Client &&
            taskDetail.taskStatus !== "Finished" && (
              <div className="flex gap-3">
                {taskDetail?.id &&
                  taskDetail.taskStatus !== "Canceled" &&
                  taskDetail.taskStatus !== "BackToRevision" &&
                  (taskDetail.taskStatus === "Suspended" ? (
                    <IsPermitted requiredPermissions={[UCM_Permissions.CanedittasksGenericTask]}>
                      <Button
                        variant={"defaultPrimary"}
                        onClick={() => {
                          setTaskType("Activate");
                          setOpenCloseSuspendDialog(true);
                        }}
                        className=""
                      >
                        Reactivate
                      </Button>
                    </IsPermitted>
                  ) : (
                    // <ReactivateTaskDialog taskId={taskDetail.task.id} />
                    <IsPermitted requiredPermissions={[UCM_Permissions.CanedittasksGenericTask]}>
                      <Button
                        onClick={() => {
                          setTaskType("Suspend");

                          setOpenCloseSuspendDialog(true);
                        }}
                      >
                        Suspend
                      </Button>
                    </IsPermitted>
                  ))}

                {taskDetail?.id &&
                  taskDetail.taskStatus !== "Canceled" &&
                  taskDetail.taskStatus !== "Suspended" &&
                  (taskDetail.taskStatus === "BackToRevision" ? (
                    <IsPermitted requiredPermissions={[UCM_Permissions.CanedittasksGenericTask]}>
                      <Button>Revise</Button>
                    </IsPermitted>
                  ) : (
                    <IsPermitted requiredPermissions={[UCM_Permissions.CanedittasksGenericTask]}>
                      <Button
                      variant={"outline"}
                      onClick={() => {
                        setTaskType("Activate");
                        setOpenCloseSuspendDialog(true);
                      }}
                      className="border-blue-400 text-blue-500 hover:bg-blue-400 hover:text-white hidden"
                      >
                      Back To Revision
                      </Button>
                    </IsPermitted>
                  ))}

                {taskDetail?.id && taskDetail.taskStatus !== "Canceled" && (
                  <>
                    <IsPermitted requiredPermissions={[UCM_Permissions.CanedittasksGenericTask]}>
                      <Button
                      variant={"outline"}
                      onClick={() => {
                        setTaskType("Cancel");
                        setOpenCloseSuspendDialog(true);
                      }}
                      className="border-red-400 text-red-500 hover:bg-red-400 hover:text-white"
                      >
                      Cancel
                      </Button>
                    </IsPermitted>
                    <IsPermitted requiredPermissions={[UCM_Permissions.CanedittasksGenericTask]}>
                      <Button
                      variant={"defaultPrimary"}
                      onClick={() => {
                        setTaskType("Close");
                        setOpenCloseSuspendDialog(true);
                      }}
                      >
                      Complete
                      </Button>
                    </IsPermitted>
                    <IsPermitted requiredPermissions={[UCM_Permissions.Cancreategenerictasksanditsactivities]}>
                      <Button
                      variant={"destructive"}
                      onClick={() => {
                        setOpenAlertDialog(true);
                      }}
                      >
                      <TriangleAlert size={18} className="mr-2" />
                      Add Alert
                      </Button>
                    </IsPermitted>
                  </>
                )}
              </div>
            )}
        </div>
      )}

      {openDialog && (
        <AddGenericActivityDialog
          openDialog={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setEditingState({
              isEditingActivity: false,
              isEditingParameter: false,
            });
            setSelectedDetail(null);
          }}
          taskId={taskDetail.id}
          isEditing={editingState.isEditingActivity || false}
          taskDetail={
            editingState.isEditingActivity && selectedDetail
              ? selectedDetail
              : null
          }
        />
      )}

      {openCloseSuspendDialog && taskType !== null && (
        <ChangeStatusOfTaskDialog
          onClose={() => {
            setOpenCloseSuspendDialog(false);
            setTaskType(null);
          }}
          open={openCloseSuspendDialog}
          taskId={taskDetail.id}
          type={taskType}
        />
      )}
      {openAlertDialog && (
        <AddGenericAlertDialog
          openAlertDialog={openAlertDialog}
          setOpenAlertDialog={setOpenAlertDialog}
          taskId={taskDetail.id}
          workflowId=""
        />
      )}
      {openEditValueDialog && (
        <UpdateGenericActivityValueDialog
          onClose={() => {
            setOpenEditValueDialog(false);
          }}
          openDialog={openEditValueDialog}
          taskId={taskDetail.id}
          taskDetail={selectedDetail}
        />
      )}
    </div>
  );
};

export default GenericTaskDetail;
