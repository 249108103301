import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEditRoleMutation, useGetRoleByIdQuery } from "../api/user-api";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Notify } from "@/components/shared/Notification/notify";
import { UCM_Permissions } from "../../../../permission";
import { useAuth } from "@/lib/auth";

const roleSchema = z.object({
    roleName: z.string().nonempty("Role name required"),
    description: z.string().optional(),
})  


interface RoleDetailProps {
    roleId: string;
}

type RoleFormValues = z.infer<typeof roleSchema>;

const DisplayTab = ({ roleId }: RoleDetailProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const {session} = useAuth()
  const {data: role, refetch} = useGetRoleByIdQuery(roleId)
  const [ EditRole, {isLoading, isSuccess}] = useEditRoleMutation()


  const form = useForm<RoleFormValues>({
    resolver: zodResolver(roleSchema),
    mode: "onChange",
    defaultValues: {
      roleName: role?.roleName,
      description: role?.description
    }
  })

  const onSubmit = async () => {
    await EditRole({
      data: {
        role: {
          id: role?.id,
          roleName: form.getValues("roleName"),
          description: form.getValues("description")
        }
      }
    })
  }


  useEffect(() => {
    refetch()
  }, [roleId])

  useEffect(() => {
    if (role) {
      form.reset({
        roleName: role.roleName,
        description: role.description
      });
    }
  }, [role]);

  useEffect(() => {
    if(isSuccess) Notify("success", "Role updated successfully!")
  }, [isSuccess])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 p-4">
        <FormField
          control={form.control}
          name="roleName"
          defaultValue={role?.roleName}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role Name<span className="text-red-500 ml-1">*</span></FormLabel>
              <FormControl>
                <Input placeholder="Role Name" {...field} disabled={role?.roleName === 'Owner' || !session?.userInfo.permissions.includes(UCM_Permissions.CanEditrolesRole)}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          defaultValue={role?.description}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description<span className="text-red-500 ml-1">*</span></FormLabel>
              <FormControl>
                <Input placeholder="Description" {...field} disabled={role?.roleName === 'Owner' || !session?.userInfo.permissions.includes(UCM_Permissions.CanEditrolesRole)}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="">
          <CustomButton
            type="submit"
            className="w-50"
            disabled={role?.roleName === 'Owner' || (!form.formState.isValid) || isLoading || !session?.userInfo.permissions.includes(UCM_Permissions.CanEditrolesRole)}
            isLoading={isLoading}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </Form>
  );
};

export default DisplayTab;