import { ISector } from "../models/Case";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Copy, Pencil, Trash2 } from "lucide-react";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useGetSectorsQuery, useUpdateSectorMutation } from "../api/case-api";
import CustomButton from "@/components/shared/Button/CustomButton";
import { FormLabel, IconButton } from "@mui/material";
import { useDeleteSectorMutation } from "../../api/work-flow-command";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { CopySectorDialog } from "./CopySector";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";

import IsPermitted from "@/lib/auth/components/IsPermitted";
import { UCM_Permissions } from "../../../../../permission";


export function SectorList() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);
  const [sectorName, setSectorName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedSector, setSelectedSector] = useState<ISector | null>(null);

  const { data: sectors = [], isLoading: sectorLoading } = useGetSectorsQuery({
    url: "/sectors",
    method: "GET",
  });

  const [updateSector, { isSuccess: isCreatingCaseSuccess, isLoading }] = useUpdateSectorMutation();
 
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedSector === null) return;
    await updateSector({
      data: {
        ...selectedSector,
        sectorName: sectorName,
        description: description,
      },
    });
  };

  useEffect(() => {
    if (isCreatingCaseSuccess) {
      setSectorName("");
      setDescription("");
      setOpenDialog(false);
      setSelectedSector(null);
    }
  }, [isCreatingCaseSuccess, setOpenDialog]);
  
  const sectorListColumns: MRT_ColumnDef<ISector>[] = [
    {
      accessorKey: "sectorName",
      header: "Name",
    },
    {
      header: "Description",
      accessorFn: (row) => row.description,
    },
    {
      accessorKey: "isActive",
      header: "Status",
      Cell: ({ row }) => {
        return (
          <Badge variant={row.original.isActive ? "default" : "destructive"}>
            {row.original.isActive ? "Active" : "In Active"}
          </Badge>
        );
      },
    },

    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      Cell: ({ row }) => {
        return (
          <div className="flex gap-4 items-center">
          <IsPermitted requiredPermissions={[UCM_Permissions.canDuplicatesector]}>
            <Button 
            variant={"outline"}
            onClick={() => {
              setOpenCopyDialog(true);
              setSelectedSector(row.original);
            }}
            >
            <Copy size={14} className="mr-2" />Copy
            </Button>
          </IsPermitted>
            <IsPermitted requiredPermissions={[UCM_Permissions.canEditsector]}>
              <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setOpenDialog(true);
                setSelectedSector(row.original);
                setSectorName(row.original.sectorName);
                setDescription(row.original.description);
              }}
              >
              <Pencil size={14} className="mr-2" />
              edit
              </Button>
            </IsPermitted>
            <IsPermitted requiredPermissions={[UCM_Permissions.canDeletesector]}>
              <IconButton
              onClick={() => {
                setSelectedSector(row.original);
                setOpenConfirmModal(true);
              }}
              >
              <Trash2 color="red" />
              </IconButton>
            </IsPermitted>
          </div>
        );
      },
    },
  ];

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [
    deleteSector,
    { isLoading: isSectorDeletingLoading, isSuccess: isSectorDeletingSuccess },
  ] = useDeleteSectorMutation();

  const handleDeleteCase = async () => {
    if (selectedSector) {
      try {
        await deleteSector(selectedSector.id);
        setOpenConfirmModal(false);
      } catch (error) {
        console.error("Failed to delete parameter", error);
      }
    }
  };

  useEffect(() => {
    if (isSectorDeletingSuccess) {
      setOpenConfirmModal(false);
      setSelectedSector(null);
    }
  }, [isCreatingCaseSuccess, isSectorDeletingSuccess]);

  return (
    <div className="rounded-md border">
      <MaterialReactTable<ISector> columns={sectorListColumns} data={sectors} state={{isLoading: sectorLoading}}/>
      {openDialog && (
        <DefaultDialog
          title="Add Sector"
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSectorName("");
            setDescription("");
            setSelectedSector(null);
          }}
        >
          <div className="mt-4">
            <form onSubmit={onSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                  <FormLabel>Sector Name<span className="text-red-500 ml-1">*</span></FormLabel>
                  <Input
                    placeholder="sector name"
                    required
                    value={sectorName}
                    onChange={(e) => setSectorName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <FormLabel>Description<span className="text-red-500 ml-1">*</span></FormLabel>
                  <Textarea
                    placeholder="description"
                    className="resize-none"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  <CustomButton
                    type="submit"
                    className="w-50 mx-auto"
                    isLoading={isLoading}
                  >
                    Submit
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        </DefaultDialog>
      )}

      {
        openCopyDialog && selectedSector && (
          <CopySectorDialog
          openDialog={openCopyDialog} 
          setOpenDialog={(value: boolean) => setOpenCopyDialog(value)} 
          targetSector={selectedSector}
          />
        )
      }

      {openConfirmModal && (
        <ConfirmModal
          message="Are you Sure you want to delete?"
          title="Delete Sector"
          modalOpen={openConfirmModal}
          setModalOpen={() => {}}
          type="error"
          customOkText="Delete"
          onCancel={() => {
            setOpenConfirmModal(false);
            setSelectedSector(null);
          }}
          onOk={() => handleDeleteCase()}
          loading={isSectorDeletingLoading}
        />
      )}
      
    </div>
  );
}
