import { useEffect } from "react";
import { Switch } from "@/components/ui/switch";
import { useGetAllPermissionsQuery, useGetPermissionsByRoleIdQuery, useOnOffPermissionsMutation } from "../api/user-api";
import { UCM_Permissions } from "../../../../permission";
import { useAuth } from "@/lib/auth";
import { IRole } from "../models/Role";

interface RoleDetailProps {
    role: IRole;
}

const PermissionsTab = ({ role }: RoleDetailProps) => {
    const {session} = useAuth()
    const {data: permissionList} = useGetAllPermissionsQuery()
    const {data: rolePermissions=[], refetch} = useGetPermissionsByRoleIdQuery({data:{id:role.id}})
    const [OnoffPermission] = useOnOffPermissionsMutation()

    const handleTogglePermission = async (permissionId: string, state: boolean) => {
        await OnoffPermission({
            data: {
                roleId: role.id,
                permissionId: permissionId,
                isOn: state
            }
        })
    }

    useEffect(() => {
        refetch()
    }, [role])

    const isPermissionDisabled = (permission: any, groupPermissions: any[]) => {
        // If role is Owner or user doesn't have permission to edit, disable all switches
        if (role?.roleName === 'Owner' || !session?.userInfo.permissions.includes(UCM_Permissions.CanseteditrolepermissionsRole)) {
            return true
        }

        // Base permission (index 0) is always enabled
        if (permission.index === 0) {
            return false
        }

        // Find the base permission (index 0) for this module
        const basePermission = groupPermissions.find(p => p.index === 0)
        if (!basePermission) return true

        // For non-base permissions, check if base permission is checked
        const isBasePermissionChecked = rolePermissions.some(rolePermission => 
            rolePermission.id === basePermission.id
        )

        // If base permission is not checked, disable all other permissions
        return !isBasePermissionChecked
    }
  
    return (
        <div className="flex flex-col gap-4 w-full">
            {permissionList?.map((group) => (
                <div key={group.moduleName} className="flex flex-col gap-2 w-full">
                    <h3>{group.moduleName}</h3>
                    {[...group.permissions] // Create a new array before sorting
                        .sort((a, b) => a.index - b.index)
                        .map((permission) => (
                            <div key={permission.id} className="w-full flex justify-between items-center border-2 rounded-lg p-4">
                                <p className="font-normal text-md">{permission.permissionName}</p>
                                <Switch
                                    checked={rolePermissions.some(rolePermission => rolePermission.id === permission.id)}
                                    onCheckedChange={(checked) => {
                                        handleTogglePermission(permission.id, checked);
                                    }}
                                    disabled={isPermissionDisabled(permission, group.permissions)}
                                />
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};

export default PermissionsTab;