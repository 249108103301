// components/form/FormField.tsx
import React from "react";
import { Dayjs } from "dayjs";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import MultiSelectInput from "./MultiSelectInput";
import DateInput from "./DateInput";
import FileInput from "./FileInput";

interface FormFieldProps {
  id: string;
  label: string;
  type: string;
  value: string | Dayjs | string[] | File | null;
  options?: string[];
  onChange: (
    id: string,
    value: string | Dayjs | string[] | File | null
  ) => void;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  id,
  label,
  type,
  value,
  options = [],
  onChange,
  disabled,
  required = false,
}) => {
  switch (type.toLowerCase()) {
    case "string":
    case "text":
    case "link":
      return (
        <TextInput
          label={label}
          value={value as string}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
          type={type.toLowerCase() == "link" ? "url" : undefined}
        />
      );

    case "list":
    case "select":
      return (
        <SelectInput
          label={label}
          value={value as string}
          options={options}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
        />
      );

    case "multiselect":
    case "multi-select":
      return (
        <MultiSelectInput
          label={label}
          value={value as string[]}
          options={options instanceof Array ? options : []}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
        />
      );

    case "date":
      return (
        <DateInput
          label={label}
          value={value ? (value as Dayjs) : null}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
        />
        // <TextInput
        //   label={label}
        //   value={value as string}
        //   onChange={(val) => onChange(id, val)}
        // />
      );
    case "attachment":
      return (
        <FileInput
          label={label}
          value={
            typeof value === "string" ? (value as string) : (value as File)
          }
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
        />
      );

    default:
      return null;
  }
};

export default FormField;
